import { getTenant } from "../../api/TenantUtils";
import { KeycloakApi } from "../../api/KeycloakApi";
import { StringThunk } from "../IStore";
import { AccessRole } from "../models/user/AccessRole";
import { TokenDto } from "../models/user/LoginDto";
import { thunkCreateErrorNotification } from "./NotificationActions";
import keycloak from "../../keycloak";

export const thunkPublicUserLogin = (): StringThunk => async (dispatch) => {
  const tenant = getTenant().toUpperCase();
  const LANDING_PAGE_PUBLIC_USER_USERNAME =
    window.env[`REACT_APP_${tenant}_PUBLIC_USER`] ?? process.env[`REACT_APP_${tenant}_PUBLIC_USER`];
  const LANDING_PAGE_PUBLIC_USER_PASSWORD =
    window.env[`REACT_APP_${tenant}_PUBLIC_PASSWORD`] ?? process.env[`REACT_APP_${tenant}_PUBLIC_PASSWORD`];

  try {
    const loginDto = {
      username: LANDING_PAGE_PUBLIC_USER_USERNAME ?? "",
      password: LANDING_PAGE_PUBLIC_USER_PASSWORD ?? "",
    };
    const rawToken = await KeycloakApi.login(loginDto);
    const keycloakToken = JSON.parse(rawToken) as KeycloakToken;

    return keycloakToken.access_token;
  } catch (e) {
    dispatch(thunkCreateErrorNotification("Error during login", e));
    return "";
  }
};

export function ensureToken(): TokenDto | undefined {
  const token = getValidToken();
  if (!token) {
    keycloak.login();
  }
  return token;
}

export function logout() {
  keycloak.logout();
}

function getValidToken(): TokenDto | undefined {
  try {
    if (!keycloak.token) return;
    const jwt = parseJwt(keycloak.token);
    if (!jwt) return; // access_token could not be parsed as an JWT
    return {
      access_token: keycloak.token,
      customer_number: jwt.customer_number,
      username: jwt.preferred_username,
      groups: jwt.groups,
    };
  } catch {
    console.error("No keycloak token found");
  }
}

function parseJwt(accessToken: string): LoyaltyToken {
  return JSON.parse(atob(accessToken.split(".")[1]));
}

interface KeycloakToken {
  access_token: string;
  expires_in: number;
  "not-before-policy": number;
  refresh_expires_in: number;
  refresh_token: string;
  scope: string;
  session_state: string;
  token_type: string;
}

interface LoyaltyToken {
  acr: string;
  "allowed-origins": string[];
  azp: string;
  customer_number: string;
  email: string;
  exp: number;
  groups: AccessRole[];
  iat: number;
  iss: string;
  jti: string;
  preferred_username: string;
  scope: string;
  session_state: string;
  sub: string;
  typ: string;
  upn: string;
}

export async function tokenRequestOptions(method: string, useToken: boolean = true) {
  const requestOptions: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (useToken) {
    requestOptions.headers = {
      ...requestOptions.headers,
      Authorization: `Bearer ${keycloak.token}`,
    };
  }
  return requestOptions;
}

export async function tokenRequestOptionsOneTime(method: string, accessToken: string) {
  const requestOptions: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
    },
  };
  requestOptions.headers = {
    ...requestOptions.headers,
    Authorization: "Bearer " + accessToken,
  };
  return requestOptions;
}

export async function tokenRequestOptionsForMultipart(method: string, useToken: boolean = true) {
  const requestOptions: RequestInit = {
    method,
  };
  if (useToken) {
    const token = ensureToken();
    if (!token) return;
    requestOptions.headers = {
      ...requestOptions.headers,
      Authorization: "Bearer " + token.access_token,
    };
  }
  return requestOptions;
}

export async function parseResponse(response: Response) {
  if (response.status === 401) {
    keycloak.login();
    return;
  }
  const text = await response.text();
  let data;
  try {
    data = JSON.parse(text);
  } catch {
    if (response.status !== 200 && response.status !== 201 && response.status !== 204) throw response.statusText;
  }
  if (data?.error) {
    throw data.error;
  }
  return data;
}

export function getCurrentUsername() {
  const loyaltyToken = ensureToken();
  return loyaltyToken?.username ?? "undefined";
}

export function canReadDashboard() {
  return keycloak.hasResourceRole(AccessRole.DASHBOARD_READONLY);
}

export function canMaintainAdminPassword() {
  return keycloak.hasResourceRole(AccessRole.ADMIN_PASSWORD_MAINTAIN);
}

export function canReadCampaigns() {
  if (getTenant().toLowerCase().includes("engelhorn")) {
    return false;
  }
  return keycloak.hasResourceRole(AccessRole.CAMPAIGN_READONLY);
}

export function canMaintainCampaigns() {
  return keycloak.hasResourceRole(AccessRole.CAMPAIGN_MAINTAIN);
}

export function canReadCashBack() {
  if (getTenant().toLowerCase().includes("engelhorn")) {
    return false;
  }
  return keycloak.hasResourceRole(AccessRole.CASHBACK_CONFIG_READONLY);
}

export function canMaintainCashBack() {
  return keycloak.hasResourceRole(AccessRole.CASHBACK_CONFIG_MAINTAIN);
}

export function canReadBonus() {
  return keycloak.hasResourceRole(AccessRole.BONUS_READONLY);
}

export function canReadAppContent() {
  return keycloak.hasResourceRole(AccessRole.APP_CONTENT_READONLY);
}

export function canMaintainAppContent() {
  return keycloak.hasResourceRole(AccessRole.APP_CONTENT_MAINTAIN);
}

export function canMaintainBonus() {
  return keycloak.hasResourceRole(AccessRole.BONUS_MAINTAIN);
}

export function canReadBonusPremiums() {
  if (getTenant().toLowerCase().includes("engelhorn")) {
    return false;
  }
  return keycloak.hasResourceRole(AccessRole.PREMIUM_READONLY);
}

export function canMaintainBonusPremiums() {
  return keycloak.hasResourceRole(AccessRole.PREMIUM_MAINTAIN);
}

export function canReadUsers() {
  return keycloak.hasResourceRole(AccessRole.ADMIN_USER_READONLY);
}

export function canMaintainUsers() {
  return keycloak.hasResourceRole(AccessRole.ADMIN_USER_MAINTAIN);
}

export function canReadPreferences() {
  if (getTenant().toLowerCase().includes("engelhorn")) {
    return false;
  }
  return keycloak.hasResourceRole(AccessRole.PREFERENCE_READONLY);
}

export function canMaintainPreferences() {
  return keycloak.hasResourceRole(AccessRole.PREFERENCE_MAINTAIN);
}

export function canReadCustomerPreferences() {
  return keycloak.hasResourceRole(AccessRole.CUSTOMER_PREFERENCE_READONLY);
}

export function canMaintainCustomerPreferences() {
  return keycloak.hasResourceRole(AccessRole.CUSTOMER_PREFERENCE_MAINTAIN);
}

export function canReadOperationalUnits() {
  return keycloak.hasResourceRole(AccessRole.UNIT_READONLY);
}

export function canMaintainOperationalUnits() {
  return keycloak.hasResourceRole(AccessRole.UNIT_MAINTAIN);
}

export function canReadCustomers() {
  return keycloak.hasResourceRole(AccessRole.CUSTOMER_READONLY);
}

export function canMaintainCustomers() {
  return keycloak.hasResourceRole(AccessRole.CUSTOMER_MAINTAIN);
}

export function canReadCircles() {
  return keycloak.hasResourceRole(AccessRole.CIRCLE_READONLY);
}

export function canMaintainCircles() {
  return keycloak.hasResourceRole(AccessRole.CIRCLE_MAINTAIN);
}

export function canReadIdentifications() {
  return keycloak.hasResourceRole(AccessRole.IDENTITY_READONLY);
}

export function canMaintainIdentifications() {
  return keycloak.hasResourceRole(AccessRole.IDENTITY_MAINTAIN);
}

export function canReadIdentificationTypes() {
  return keycloak.hasResourceRole(AccessRole.IDENTITY_TYPE_READONLY);
}

export function canMaintainIdentificationTypes() {
  return keycloak.hasResourceRole(AccessRole.IDENTITY_TYPE_MAINTAIN);
}

export function canReadFamilies() {
  return keycloak.hasResourceRole(AccessRole.FAMILY_READONLY);
}

export function canMaintainFamilies() {
  return keycloak.hasResourceRole(AccessRole.FAMILY_MAINTAIN);
}

export function canReadConfig() {
  return keycloak.hasResourceRole(AccessRole.CONFIG_READONLY);
}

export function canMaintainConfig() {
  return keycloak.hasResourceRole(AccessRole.CONFIG_MAINTAIN);
}

export function canReadFlows() {
  return keycloak.hasResourceRole(AccessRole.FLOW_READONLY);
}

export function canMaintainFlows() {
  return keycloak.hasResourceRole(AccessRole.FLOW_MAINTAIN);
}

export function canReadGdpr() {
  return keycloak.hasResourceRole(AccessRole.GDPR_READONLY);
}

export function canMaintainGdpr() {
  return keycloak.hasResourceRole(AccessRole.GDPR_MAINTAIN);
}

export function canReadCustomerInteractions() {
  return keycloak.hasResourceRole(AccessRole.CUSTOMER_INTERACTION_READONLY);
}

export function canMaintainCustomerInteractions() {
  return keycloak.hasResourceRole(AccessRole.CUSTOMER_INTERACTION_MAINTAIN);
}

export function canReadSuperUser() {
  return keycloak.hasResourceRole(AccessRole.SUPERUSER_READONLY);
}

export function canMaintainSuperUser() {
  return keycloak.hasResourceRole(AccessRole.SUPERUSER_MAINTAIN);
}

export function canReadPropertyGroup() {
  return keycloak.hasResourceRole(AccessRole.PROPERTYGROUP_READONLY);
}

export function canMaintainPropertyGroup() {
  return keycloak.hasResourceRole(AccessRole.PROPERTYGROUP_MAINTAIN);
}

export function canReadReports() {
  if (getTenant().toLowerCase().includes("engelhorn")) {
    return false;
  }
  return keycloak.hasResourceRole(AccessRole.REPORT_READONLY);
}
