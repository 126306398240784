import { Grid, Paper, useTheme } from "@mui/material";
import countries from "iso3166-2-db/countryList/dispute/UN/de.json";
import { AddressDto } from "../../store/models/customer/AddressDto";
import { LoginDto, RegistrationDto } from "../../store/models/registration/RegistrationDto";
import { FormInput } from "../atoms/FormInput";
import { FormSelect } from "../atoms/FormSelect";
import ImsPaperHead from "../atoms/ImsPaperHead";
import { nameof, nameof2 } from "../atoms/Utils";
import { getLabelText, RegistrationFormThunkProps } from "./RegistrationForm";
import { useTranslation } from "react-i18next";

const countryList = Object.entries(countries)
  .map(([k, v]) => ({
    value: k,
    label: v.name,
  }))
  .sort((a, b) => a.label.localeCompare(b.label));

const Contact = (props: RegistrationFormThunkProps) => {
  const theme = useTheme();
  const { registrationFieldConfig } = props;
  const { t } = useTranslation();
  return (
    <>
      <Paper
        style={{
          padding: theme.spacing(3),
          marginTop: theme.spacing(6),
          marginBottom: theme.spacing(6),
        }}
      >
        <ImsPaperHead text={t("registration.headlineContact")} />
        <Grid container spacing={4}>
          <Grid
            container
            spacing={4}
            style={{
              margin: theme.spacing(0),
            }}
          >
            <Grid item md={4}>
              <FormInput
                type="text"
                name={nameof2<RegistrationDto, LoginDto>("login", "username")}
                label={getLabelText(
                  t("registration.eMail"),
                  nameof2<RegistrationDto, LoginDto>("login", "username"),
                  registrationFieldConfig
                )}
                variant="outlined"
                fullWidth={true}
                fieldConfig={registrationFieldConfig}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={4}
            style={{
              margin: theme.spacing(0),
            }}
          >
            <Grid item md={4}>
              <FormInput
                type="text"
                fullWidth={true}
                name={nameof2<RegistrationDto, AddressDto>("address", "streetName")}
                label={getLabelText(
                  t("registration.street"),
                  nameof2<RegistrationDto, AddressDto>("address", "streetName"),
                  registrationFieldConfig
                )}
                variant="outlined"
                fieldConfig={registrationFieldConfig}
              />
            </Grid>
            <Grid item md={1}>
              <FormInput
                type="text"
                fullWidth={true}
                name={nameof2<RegistrationDto, AddressDto>("address", "houseNumber")}
                label={getLabelText(
                  t("registration.number"),
                  nameof2<RegistrationDto, AddressDto>("address", "houseNumber"),
                  registrationFieldConfig
                )}
                variant="outlined"
                fieldConfig={registrationFieldConfig}
              />
            </Grid>
            <Grid item md={3}>
              <FormInput
                type="text"
                fullWidth={true}
                name={nameof2<RegistrationDto, AddressDto>("address", "addressAddition")}
                label={getLabelText(
                  t("registration.addressSuffix"),
                  nameof2<RegistrationDto, AddressDto>("address", "addressAddition"),
                  registrationFieldConfig
                )}
                variant="outlined"
                fieldConfig={registrationFieldConfig}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={4}
            style={{
              margin: theme.spacing(0),
            }}
          >
            <Grid item md={2}>
              <FormInput
                type="text"
                fullWidth={true}
                name={nameof2<RegistrationDto, AddressDto>("address", "zipCode")}
                label={getLabelText(
                  t("registration.postCode"),
                  nameof2<RegistrationDto, AddressDto>("address", "zipCode"),
                  registrationFieldConfig
                )}
                variant="outlined"
                fieldConfig={registrationFieldConfig}
              />
            </Grid>
            <Grid item md={6}>
              <FormInput
                type="text"
                fullWidth={true}
                name={nameof2<RegistrationDto, AddressDto>("address", "cityName")}
                label={getLabelText(
                  t("registration.location"),
                  nameof2<RegistrationDto, AddressDto>("address", "cityName"),
                  registrationFieldConfig
                )}
                variant="outlined"
                fieldConfig={registrationFieldConfig}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={4}
            style={{
              margin: theme.spacing(0),
            }}
          >
            <Grid item md={4}>
              <FormSelect
                name={nameof2<RegistrationDto, AddressDto>("address", "country")}
                label={getLabelText(
                  t("registration.country"),
                  nameof2<RegistrationDto, AddressDto>("address", "country"),
                  registrationFieldConfig
                )}
                options={countryList}
                fullWidth={true}
                fieldConfig={registrationFieldConfig}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={4}
            style={{
              margin: theme.spacing(0),
            }}
          >
            <Grid item md={4}>
              <FormInput
                type="text"
                fullWidth={true}
                name={nameof<RegistrationDto>("phoneNumber")}
                label={getLabelText(t("registration.telephone"), nameof<RegistrationDto>("phoneNumber"), registrationFieldConfig)}
                variant="outlined"
                fieldConfig={registrationFieldConfig}
              />
            </Grid>
            <Grid item md={4}>
              <FormInput
                type="text"
                fullWidth={true}
                name={nameof<RegistrationDto>("mobileNumber")}
                label={t("registration.mobile")}
                variant="outlined"
                fieldConfig={registrationFieldConfig}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default Contact;
