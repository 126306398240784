import { getLabel, PassMediumDto } from "../../../store/models/passmedium/PassMediumDto";
import { ImsHeadCell, ImsTable, ImsTableRow } from "../../atoms/ImsTable";
import PassMediumTableMenu from "./PassMediumTableMenu";
import { IStore } from "../../../store/IStore";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { thunkGetAllPassMediums } from "../../../store/actions/PassMediumAction";
import { useTranslation } from "react-i18next";

const PassMediumTable = (props: ThunkProps) => {
  const { passMediums } = props;
  const { t } = useTranslation();
  const headCells: ImsHeadCell<PassMediumDto>[] = [
    { val: (val) => val.motiv, node: t("setup.headlineMotive") },
    { val: (val) => val.type, node: t("setup.headlineFormat") },
  ];

  const rows: ImsTableRow<PassMediumDto>[] = passMediums?.map((row: PassMediumDto) => ({
    value: row,
    nodes: [row.motiv, getLabel(row.type), <PassMediumTableMenu passMedium={row} />],
  }));

  return rows.length ? (
    <ImsTable hasMenu={true} headCells={headCells} rows={rows} sortColumnIndex={1} sortDirection="desc" />
  ) : (
    <>{t("setup.noData")}</>
  );
};

const mapStateToProps = (state: IStore) => ({
  passMediums: state.passMediums.passMediums,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkGetAllPassMediums,
    },
    dispatch
  );
const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(PassMediumTable);
