import { Api } from "../../api/Api";
import { BooleanThunk } from "../IStore";
import { thunkCreateErrorNotification, thunkCreateSuccessNotification } from "./NotificationActions";
import { AppLinkDto } from "../models/appcontent/AppLinkDto";

export const GET_ALL_APP_LINKS = "GET_ALL_APP_LINKS";
export const CHANGE_APP_LINKS = "CHANGE_APP_LINKS";
export const CREATE_APP_LINK = "CREATE_APP_LINK";
export const UPDATE_APP_LINK = "UPDATE_APP_CONTENT";
export const DELETE_APP_LINK = "DELETE_APP_CONTENT";

export const thunkGetAllAppLinks = (): BooleanThunk => async (dispatch) => {
  try {
    const appLinks = await Api.getAllAppLinks();
    dispatch({
      type: GET_ALL_APP_LINKS,
      payload: appLinks,
    });
    return true;
  } catch (e) {
    dispatch(thunkCreateErrorNotification("Failed to load app contents", e));
    return false;
  }
};

export const thunkChangeAppLink =
  (appLinksToChange: AppLinkDto[]): BooleanThunk =>
  async (dispatch) => {
    try {
      const appLinks = await Api.changeAppLink(appLinksToChange);
      dispatch({
        type: CHANGE_APP_LINKS,
        payload: appLinks,
      });
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to load app contents", e));
      return false;
    }
  };

export const thunkCreateAppContent =
  (appContentToCreate: AppLinkDto): BooleanThunk =>
  async (dispatch) => {
    try {
      const appContent = await Api.createAppLink(appContentToCreate);
      dispatch({
        type: CREATE_APP_LINK,
        payload: appContent,
      });
      dispatch(thunkCreateSuccessNotification("App content created successfully"));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to create app content", e));
      return false;
    }
  };

export const thunkUpdateAppContent =
  (appContentToUpdate: AppLinkDto): BooleanThunk =>
  async (dispatch) => {
    try {
      const appContent = await Api.updateAppLink(appContentToUpdate);
      dispatch({
        type: UPDATE_APP_LINK,
        payload: appContent,
      });
      dispatch(thunkCreateSuccessNotification("App content updated successfully"));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to update app content", e));
      return false;
    }
  };

export const thunkDeleteAppLink =
  (appContentToDelete: AppLinkDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.deleteAppLink(appContentToDelete);
      dispatch({
        type: DELETE_APP_LINK,
        payload: appContentToDelete,
      });
      dispatch(thunkCreateSuccessNotification("App content deleted successfully"));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to delete app content", e));
      return false;
    }
  };
