export enum AdjustmentType {
  EARN = "EARN",
  BURN = "BURN",
}

export const getAdjustmentTypeOptions = (tFunction: (key: string) => string) => [
  { value: AdjustmentType.EARN, label: tFunction('historyFeed.interaction.goodwillCredit') },
  { value: AdjustmentType.BURN, label: tFunction('historyFeed.interaction.debit') },
];

export interface AdjustmentDto {
  customerNumber: string;
  type: AdjustmentType;
  externalUnitNumber?: string;
  bonusPoints: string;
  note: string;
}
