import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Grid, Paper, Typography, useTheme } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkGetCustomerRegistrations } from "../../store/actions/DashboardActions";
import { IStore } from "../../store/IStore";
import { COLOR_BLACK, COLOR_GRAY_1 } from "../atoms/ImsMaterialTheme";
import CustomerRegistrationChart from "./CustomerRegistrationChart";
import CustomerRegistrationCount from "./CustomerRegistrationCount";
import { useTranslation } from "react-i18next";

const CustomerRegistrationPanel = (props: ThunkProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { customerRegistrations } = props;
  if (!customerRegistrations) {
    props.thunkGetCustomerRegistrations();
    return null;
  }
  return (
    <>
      <Paper
        style={{
          padding: theme.spacing(3),
          paddingBottom: theme.spacing(6),
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(6),
        }}
      >
        <Grid item xs={12} style={{ marginBottom: theme.spacing(3) }}>
          <Typography variant="h3">{t("dashboard.newMember")}</Typography>
        </Grid>
        <Grid item md={12} style={{ display: "flex", alignItems: "center" }}>
          <Grid item>
            <ArrowBackIos
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.thunkGetCustomerRegistrations((customerRegistrations.offset || 0) + 1);
              }}
            />
          </Grid>
          <Grid item md>
            <CustomerRegistrationChart />
          </Grid>
          <Grid item>
            <ArrowForwardIos
              onClick={() => {
                if (customerRegistrations.offset) props.thunkGetCustomerRegistrations(customerRegistrations.offset - 1);
              }}
              style={{
                color: customerRegistrations.offset ? COLOR_BLACK : COLOR_GRAY_1,
                cursor: customerRegistrations.offset ? "pointer" : undefined,
              }}
            />
          </Grid>
          <Grid
            item
            md={3}
            style={{
              marginLeft: theme.spacing(1),
            }}
          >
            <Grid
              item
              md
              style={{
                paddingBottom: "25%",
              }}
            >
              <CustomerRegistrationCount />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

const mapStateToProps = (state: IStore) => ({
  customerRegistrations: state.dashboard.customerRegistrations,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkGetCustomerRegistrations }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(CustomerRegistrationPanel);
