export enum Gender {
  DIVERSE = "DIVERSE",
  FEMALE = "FEMALE",
  MALE = "MALE",
  ANY = "ANY",
}

export const getGenderOptions = (t: (key: string) => string) => [
  { value: Gender.ANY, label: t("common.genderAny") },
  { value: Gender.MALE, label: t("common.genderMale") },
  { value: Gender.FEMALE, label: t("common.genderFemale") },
  { value: Gender.DIVERSE, label: t("common.genderDivers") },
];

export const getGenderText = (t: (key: string) => string, gender?: Gender) => {
  const options = getGenderOptions(t);
  return options.find((option) => option.value === gender)?.label;
};
