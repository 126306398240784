import { TFunction } from "i18next";

export enum CustomerInteractionType {
  ADD_PREFERENCES = "ADD_PREFERENCES",
  REGISTER = "REGISTER",
  ADD_ADDRESS = "ADD_ADDRESS",
  FRIEND_INVITATION = "FRIEND_INVITATION",
  FAMILY_INVITATION = "FAMILY_INVITATION",
  CONSENT = "CONSENT",
  DELETE_BONUS_POINTS = "DELETE_BONUS_POINTS",
  EMAIL_CORRESPONDENCE_ALLOWED = "EMAIL_CORRESPONDENCE_ALLOWED",
  CUSTOM = "CUSTOM",
}

export function isCustomInteractionType(customerInteractionType?: string): boolean {
  switch (customerInteractionType) {
    // DO NOT ADD CONSENT HERE!
    case CustomerInteractionType.ADD_ADDRESS:
    case CustomerInteractionType.ADD_PREFERENCES:
    case CustomerInteractionType.DELETE_BONUS_POINTS:
    case CustomerInteractionType.EMAIL_CORRESPONDENCE_ALLOWED:
    case CustomerInteractionType.FAMILY_INVITATION:
    case CustomerInteractionType.FRIEND_INVITATION:
    case CustomerInteractionType.REGISTER:
    case CustomerInteractionType.CUSTOM:
      return false;
    default:
      return Boolean(customerInteractionType);
  }
}

export function formatCustomerInteractionType(customerInteractionType: CustomerInteractionType, t: TFunction): string {
  switch (customerInteractionType) {
    case CustomerInteractionType.ADD_ADDRESS:
      return t("historyFeed.interaction.addAddress");
    case CustomerInteractionType.ADD_PREFERENCES:
      return t("historyFeed.interaction.addPreferences");
    case CustomerInteractionType.REGISTER:
      return t("historyFeed.interaction.register");
    case CustomerInteractionType.FAMILY_INVITATION:
      return t("historyFeed.interaction.familyInvitation");
    case CustomerInteractionType.FRIEND_INVITATION:
      return t("historyFeed.interaction.friendInvitation");
    case CustomerInteractionType.CONSENT:
      return t("historyFeed.interaction.consent");
    case CustomerInteractionType.EMAIL_CORRESPONDENCE_ALLOWED:
      return t("historyFeed.interaction.emailCorrespondenceAllowed");
    default:
      return customerInteractionType;
  }
}
