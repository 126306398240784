import { Typography } from "@mui/material";
import { formatCustomerInteractionType } from "../../store/models/bonusRule/CustomerInteractionType";
import { CustomerInteractionDto, InteractionType } from "../../store/models/customer/CustomerInteractionDto";
import { FamilyDto } from "../../store/models/family/FamilyDto";
import { CustomerHistoryDto } from "../../store/models/history/HistoryDto";
import { OperationalUnitDto } from "../../store/models/operationalUnit/OperationalUnitDto";
import { getYearFromTimestamp } from "../atoms/Utils";
import { useTranslation } from "react-i18next";

export interface PaymentModel extends CustomerInteractionDto {
  displayedPaymentAmount: number;
  displayedCashValue: number;
  bonusReasons?: React.ReactElement[];
  year?: number;
  unitName?: string;
}

export const getPaymentModel = (
  customerHistory: CustomerHistoryDto[],
  operationalUnits: OperationalUnitDto[],
  filter: (history: PaymentModel) => unknown
): PaymentModel[] =>
  customerHistory
    .filter((history) => history.interaction)
    .map((history) => history.interaction!)
    .map((interaction) => ({
      displayedPaymentAmount: Number(
        interaction.paymentAmount || (interaction.reversalAmount && -interaction.reversalAmount)
      ),
      displayedCashValue: Number((interaction.cashValue && -interaction.cashValue) || interaction.reversalCashValue),
      bonusReasons: interaction.rewardedInteractions?.map((interaction) => (
        <>
          {interaction.bonusReason}
          <br />
        </>
      )),
      year: getYearFromTimestamp(interaction.interactionTimestamp),
      unitName: operationalUnits.find((unit) => unit.externalUnitNumber === interaction.externalUnitNumber)?.unitName,
      ...interaction,
    }))
    .filter(filter);

export function formatCustomerName(customerNumber: string, family?: FamilyDto) {
  const customer = family?.familyMembers.find((customers) => customers.customerNumber === customerNumber)?.customerDto;
  return customer?.firstName || customer?.mailAddress || "";
}

export function useFormatInteractionCol() {
  const { t } = useTranslation();
  return (row: PaymentModel) => {
    switch (row.interactionType) {
      case InteractionType.PAYMENT_INTERACTION:
        return (
          <>
            <b>
              {t("historyFeed.interaction.purchase")} | {row.unitName}
            </b>
            <Typography align="left" variant="body1">
              {row.bonusReasons}
            </Typography>
            <Typography align="left" variant="body1">
              {t("historyFeed.interaction.paid")}
            </Typography>
          </>
        );
      case InteractionType.REVERSAL_INTERACTION:
        return (
          <>
            {t("historyFeed.interaction.purchaseReversal")} | {row.unitName}
          </>
        );
      case InteractionType.COUPON_INTERACTION:
        return t("historyFeed.interaction.couponPurchased");
      case InteractionType.BONUS_XPRESS_INTERACTION:
        return (
          <>
            {t("historyFeed.interaction.bonusXpress")} | {row.unitName}
          </>
        );
      case InteractionType.BONUS_XPRESS_REVERSAL_INTERACTION:
        return (
          <>
            {t("historyFeed.interaction.bonusXpress")} | {row.unitName}
          </>
        );
      case InteractionType.CUSTOMER_INTERACTION:
        return formatCustomerInteractionType(row.customerInteractionType!, t);
      case InteractionType.ADJUSTMENT_EARN_INTERACTION:
        return `${t("historyFeed.interaction.goodwillCredit")} | ${row.unitName}`;
      case InteractionType.ADJUSTMENT_BURN_INTERACTION:
        return `${t("historyFeed.interaction.debit")} | ${row.unitName}`;
      case InteractionType.EXPIRED_REWARD_INTERACTION:
        return t("historyFeed.interaction.expiredBonusPoints");
      case InteractionType.CLEAR_BONUS_POINTS_INTERACTION:
        return t("historyFeed.interaction.clearBonusPoints");
      default:
        return row.interactionType;
    }
  };
}
