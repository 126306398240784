import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../../store/IStore";
import { IdentificationTypeDto } from "../../../store/models/identification/IdentificationTypeDto";
import MenuLayout from "../../app/MenuLayout";
import IdentificationTypeForm from "./IdentificationTypeForm";
import { useTranslation } from "react-i18next";

const defaultIdentificationType: IdentificationTypeDto = {
  identificationTypeNumber: "",
  externalIdentificationTypeNumber: "",
  identificationTypeName: "",
  defaultIdentification: false,
  templateName: "",
};

const IdentificationTypeFormPage = (props: ThunkProps) => {
  const { identificationTypes } = props;
  const { id } = useParams<"id">();
  const { t } = useTranslation()
  const identificationType = identificationTypes.find(
    (identificationType) => identificationType.identificationTypeNumber === id
  );

  return (
    <MenuLayout headline={id ? t("setup.editPassTemplate") : t("setup.createPassTemplate")}>
      <IdentificationTypeForm identificationType={identificationType || defaultIdentificationType} />
    </MenuLayout>
  );
};

const mapStateToProps = (state: IStore) => ({
  identificationTypes: state.identifications.identificationTypes,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(IdentificationTypeFormPage);
