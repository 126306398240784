import { Button, Grid, Paper, useTheme } from "@mui/material";
import { useState } from "react";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkCreateFlow, thunkUpdateFlow } from "../../store/actions/FlowActions";
import { IStore } from "../../store/IStore";
import { CircleDto } from "../../store/models/circle/CircleDto";
import {
  getBonusCurrencyOptions,
  getPeriodStartOptions,
  getPeriodDurationUnitOptions,
  getFlowDirectionOptions,
} from "../../store/models/flow/FlowDto";
import {
  FlowDetailsDto,
  FlowDirection,
  FlowGroupDto,
  FlowType,
  FlowTypeTrans,
  PeriodStartType,
} from "../../store/models/flow/FlowDto";
import { IdentificationTypeDto } from "../../store/models/identification/IdentificationTypeDto";
import { FormDate } from "../atoms/FormDate";
import { FormInput } from "../atoms/FormInput";
import { FormSelect } from "../atoms/FormSelect";
import ImsPaperHead from "../atoms/ImsPaperHead";
import { ImsHeadCell, ImsTable, ImsTableRow } from "../atoms/ImsTable";
import { getCurrentDateAsString, nameof, ValidationErrors } from "../atoms/Utils";
import FlowDetailsDialog from "./FlowDetailsDialog";
import FlowTableMenu from "./FlowTableMenu";
import { FormCheckbox } from "../atoms/FormCheckbox";
import { useTranslation } from "react-i18next";

interface FlowFormProps {
  flow: FlowGroupDto;
  flowType: FlowType;
}

const FlowForm = (props: FlowFormProps & ThunkProps) => {
  const { t } = useTranslation();
  const options = getBonusCurrencyOptions(t);
  const periodStartOptions = getPeriodStartOptions(t);
  const periodDurationUnitOptions = getPeriodDurationUnitOptions(t);
  const theme = useTheme();
  const cleanFlowDetail: FlowDetailsDto = {
    flowDetailNumber: "",
    threshold: "",
    flowDirection: FlowDirection.UPGRADE,
    origin: "",
    target: "",
    immediate: true,
  };
  const [flowDetailsDialogVisible, setFlowDetailsDialogVisible] = useState(false);
  const [editflowDetails, setEditFlowDetails] = useState(cleanFlowDetail);
  const getCircleNameFromNumber = (circleNumber?: string) => {
    return props.circles.find((circleObj: CircleDto) => circleObj.circleNumber === circleNumber)?.circleName;
  };
  const getIdentificationTypesFromNumber = (identificationTypeNumber?: string) => {
    return props.identificationTypes.find(
      (identObj: IdentificationTypeDto) => identObj.identificationTypeNumber === identificationTypeNumber
    )?.identificationTypeName;
  };
  const getNameFromNumber = (number?: string) => {
    return props.flowType === FlowType.CIRCLE
      ? getCircleNameFromNumber(number)
      : getIdentificationTypesFromNumber(number);
  };

  const headCells: ImsHeadCell<FlowDetailsDto>[] = [
    { val: (val) => val.flowDirection, node: t("flow.labelDirection") },
    { val: (val) => val.origin, node: t("flow.labelStart") + FlowTypeTrans[props.flowType] },
    { val: (val) => val.target, node: t("flow.labelTarget") + FlowTypeTrans[props.flowType] },
    { val: (val) => val.threshold, node: t("flow.labelTreshold") },
    { val: (val) => val.immediate, node: t("flow.tableHeadlineExecute") },
  ];

  const flowDirectionOptions = getFlowDirectionOptions(t);

  const rows: ImsTableRow<FlowDetailsDto>[] | undefined = props?.flow?.flowDetails?.map((row, index) => ({
    value: row,
    nodes: [
      flowDirectionOptions.find((option) => option.value === row.flowDirection)?.label,
      getNameFromNumber(row.origin),
      getNameFromNumber(row.target),
      row.threshold,
      row.immediate ? t("flow.yes") : t("flow.no"),
      <FlowTableMenu
        key={index}
        flow={row}
        flowGroupNumber={props.flow.flowGroupNumber}
        flowDetailsNumber={row.flowDetailNumber}
        setEditFlowDetails={setEditFlowDetails}
        setFlowDetailsDialogVisible={setFlowDetailsDialogVisible}
        getNameFromNumber={getNameFromNumber}
      />,
    ],
  }));

  const circleOptions = props.circles.map((circle) => ({
    value: circle.circleNumber,
    label: circle.circleName,
  }));

  const identificationTypeOptions = props.identificationTypes.map((identificationType) => ({
    value: identificationType.identificationTypeNumber,
    label: identificationType.identificationTypeName,
  }));

  const submit = async (flowToSave: FlowGroupDto) => {
    if (flowToSave.flowGroupNumber) {
      await props.thunkUpdateFlow(flowToSave);
    } else {
      await props.thunkCreateFlow(flowToSave);
    }
  };

  const validateForm = (values: FlowGroupDto) => {
    const errors: ValidationErrors<FlowGroupDto> = {};
    if (!values.bonusCurrency) {
      errors.bonusCurrency = t("flow.bonusCurrencyError");
    }
    if (props.flowType === FlowType.CIRCLE) {
      if (values.periodStartType === PeriodStartType.FIXED_DATE) {
        if (!values.periodStartValue) {
          errors.periodStartValue = t("flow.periodStartError");
        } else if (values.periodStartValue > getCurrentDateAsString()) {
          errors.periodStartValue = t("flow.periodStartValueError");
        }
      }

      if (!values.periodDuration) {
        errors.periodDuration = t("flow.periodDurationError");
      }
      if (!values.periodDurationUnit) {
        errors.periodDurationUnit = t("flow.periodDurationUnitError");
      }
      if (!values.periodStartType) {
        errors.periodStartType = t("flow.periodStartTypeError");
      }
    }

    return errors;
  };

  const { flow } = props;

  if (!flow.flowType) {
    flow.flowType = props.flowType;
    flow.carryOver = true;
  }

  return (
    <Form
      onSubmit={submit}
      initialValues={flow}
      validate={validateForm}
      render={({ handleSubmit, submitting, values }) => {
        if (values.periodStartType === PeriodStartType.START_OF_YEAR) {
          delete values.periodStartValue;
        }
        return (
          <form onSubmit={handleSubmit}>
            <Paper
              style={{
                padding: theme.spacing(3),
                marginTop: theme.spacing(6),
              }}
            >
              {flowDetailsDialogVisible && (
                <FlowDetailsDialog
                  flowDetail={editflowDetails}
                  flowType={props.flowType}
                  flowGroupNumber={props.flow.flowGroupNumber}
                  setFlowDetailsDialogVisible={setFlowDetailsDialogVisible}
                  circleOptions={circleOptions}
                  identificationTypeOptions={identificationTypeOptions}
                />
              )}
              <ImsPaperHead text={t("flow.headline")} />

              <Grid container spacing={2}>
                <Grid item md={3}>
                  <FormSelect
                    options={options}
                    name={nameof<FlowGroupDto>("bonusCurrency")}
                    label={t("flow.inputPlaceholderTitle")}
                    fullWidth
                  />
                </Grid>
                {props.flowType === FlowType.CIRCLE ? (
                  <>
                    <Grid item md={2}>
                      <FormCheckbox name={nameof<FlowGroupDto>("negativeAllowed")} label={t("flow.checkboxPositive")} />
                    </Grid>
                    <Grid item md={2}>
                      <FormCheckbox name={nameof<FlowGroupDto>("carryOver")} label={t("flow.checkboxTransfer")} />
                    </Grid>
                    <Grid item md={5}></Grid>
                  </>
                ) : (
                  <Grid item md={9}></Grid>
                )}

                {props.flowType === FlowType.CIRCLE ? (
                  <>
                    <Grid item md={3}>
                      <FormSelect
                        options={periodStartOptions}
                        name={nameof<FlowGroupDto>("periodStartType")}
                        label={t("flow.labelTimeInterval")}
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={2}>
                      <FormDate
                        name={nameof<FlowGroupDto>("periodStartValue")}
                        label={t("flow.inputPlaceholderStartDate")}
                        fullWidth
                        readOnly={values.periodStartType !== PeriodStartType.FIXED_DATE}
                      />
                    </Grid>
                    <Grid item md={2}>
                      <FormInput
                        type="number"
                        name={nameof<FlowGroupDto>("periodDuration")}
                        label={t("flow.labelTimeIntervalDuration")}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item md={2}>
                      <FormSelect
                        options={periodDurationUnitOptions}
                        name={nameof<FlowGroupDto>("periodDurationUnit")}
                        label={t("flow.labelTimeIntervalUnit")}
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={3}></Grid>
                  </>
                ) : null}

                <Grid container justifyContent="flex-end">
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    style={{ margin: theme.spacing(2, 0, 2, 2) }}
                  >
                    {t("flow.buttonSave")}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
            <Paper
              style={{
                padding: theme.spacing(3),
                marginTop: theme.spacing(6),
              }}
            >
              <Grid container item mb={2}>
                <Grid container item>
                  <ImsPaperHead text={t("flow.headlineFlowDetails")} />
                </Grid>
                <Grid container item justifyContent="flex-end">
                  <Button
                    onClick={() => {
                      setFlowDetailsDialogVisible(true);
                      setEditFlowDetails(cleanFlowDetail);
                    }}
                    startIcon="+"
                    variant="contained"
                    color="primary"
                    disabled={flow.flowGroupNumber ? false : true}
                  >
                    {t("flow.buttonNewDetailFlow")}
                  </Button>
                </Grid>
              </Grid>
              {rows ? <ImsTable hasMenu={true} headCells={headCells} rows={rows} /> : "Kein Flow Details vorhanden"}
            </Paper>
          </form>
        );
      }}
    />
  );
};
const mapStateToProps = (state: IStore) => ({
  circles: state.circles.circles,
  identificationTypes: state.identifications.identificationTypes,
  bonusPremiums: state.bonusPremiums.bonusPremiums,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkUpdateFlow,
      thunkCreateFlow,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(FlowForm);
