import { Grid } from "@mui/material";
import { canReadDashboard } from "../../store/actions/LoginActions";
import MenuLayout from "../app/MenuLayout";
import { COLOR_GRAY_2 } from "../atoms/ImsMaterialTheme";
import BonusPanel from "./BonusPanel";
import CustomerActivityPanel from "./CustomerActivityPanel";
import CustomerRegistrationPanel from "./CustomerRegistrationPanel";
import CustomerStatusPanel from "./CustomerStatusPanel";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

export const MainDashboardPage = () => {
  const { t } = useTranslation();

  return (
    <MenuLayout headline={t("dashboard.title")}>
      <Grid container>
        <Grid item md={12}>
          {canReadDashboard() && (
            <>
              <CustomerActivityPanel />
              <BonusPanel />
              <CustomerRegistrationPanel />
              <CustomerStatusPanel />
            </>
          )}
        </Grid>
      </Grid>
    </MenuLayout>
  );
};

export const chartColors = () => (
  <defs>
    <linearGradient id="colorGray" x1="0" y1="0" x2="0" y2="100%" spreadMethod="reflect">
      <stop stopColor={COLOR_GRAY_2} />
      <stop offset="1" stopColor="rgba(102, 102, 102, 0.5)" />
    </linearGradient>
  </defs>
);

export const formatAxisDate = (dateString: string) => {
  const t = i18n.t;
  const date = new Date(dateString);
  return `${date.getDate()}.${date.getMonth() + 1}.\n${
    [
      t("dashboard.daysOfTheWeek.short.monday"),
      t("dashboard.daysOfTheWeek.short.tuesday"),
      t("dashboard.daysOfTheWeek.short.wednesday"),
      t("dashboard.daysOfTheWeek.short.thursday"),
      t("dashboard.daysOfTheWeek.short.friday"),
      t("dashboard.daysOfTheWeek.short.saturday"),
      t("dashboard.daysOfTheWeek.short.sunday"),
    ][(date.getDay() + 6) % 7]
  }`;
};
