import { CustomerDto } from "../customer/CustomerDto";
import { useTranslation } from "react-i18next";

export interface FamilyMembershipDto {
  customerNumber: string;
  roleName: FamilyMembershipRole;
  customerDto?: CustomerDto;
}

export enum FamilyMembershipRole {
  HEAD = "HEAD",
  MEMBER = "MEMBER",
  HEAD_COLLECTOR = "HEAD_COLLECTOR",
}

export interface FamilyRoleDto {
  [customerNumber: string]: FamilyMembershipRole;
}

export const memberRoleOptions = [
  {
    label: "Sammeln und Einlösen",
    value: FamilyMembershipRole.HEAD,
  },
  {
    label: "Sammeln",
    value: FamilyMembershipRole.MEMBER,
  },
  {
    label: "Sammeln und Einlösen (inkl. Cashback)",
    value: FamilyMembershipRole.HEAD_COLLECTOR,
  },
];

export const useMemberRoleOptions = () => {
  const { t } = useTranslation();
  return [
    {
      label: t("familyPage.labelRoleHead"),
      value: FamilyMembershipRole.HEAD,
    },
    {
      label: t("familyPage.labelRoleMember"),
      value: FamilyMembershipRole.MEMBER,
    },
    {
      label: t("familyPage.labelRoleHeadCollector"),
      value: FamilyMembershipRole.HEAD_COLLECTOR,
    },
  ];
};

export interface FamilyMembershipRoleDto {
  roleName: FamilyMembershipRole;
}
