import { Api } from "../../api/Api";
import { BooleanThunk } from "../IStore";
import { InvitationDto } from "../models/InvitationDto";
import { thunkCreateErrorNotification, thunkCreateSuccessNotification } from "./NotificationActions";
import { FamilyMembersDto } from "../models/family/FamilyMembersDto";

export const GET_FAMILY_BY_CUSTOMER_NUMBER = "GET_FAMILY_BY_CUSTOMER_NUMBER";
export const DELETE_FAMILY_MEMBER = "DELETE_FAMILY_MEMBER";
export const UPDATE_FAMILY_MEMBERSHIP = "UPDATE_FAMILY_MEMBERSHIP";
export const JOIN_FAMILY = "JOIN_FAMILY";

export const thunkGetFamilyByCustomerNumber =
  (customerNumber: string): BooleanThunk =>
  async (dispatch) => {
    try {
      const family = await Api.getFamilyByCustomerNumber(customerNumber);
      dispatch({
        type: GET_FAMILY_BY_CUSTOMER_NUMBER,
        payload: family,
      });
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to load family", e));
      return false;
    }
  };

export const thunkDeleteFamilyMembership =
  (familyNumber: string, customerNumber: string): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.deleteFamilyMembership(familyNumber, customerNumber);
      dispatch({
        type: DELETE_FAMILY_MEMBER,
        payload: customerNumber,
      });
      dispatch(thunkCreateSuccessNotification("Die Familienmitgliedschaft wurde gelöscht"));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to delete family membership", e));
      return false;
    }
  };

export const thunkUpdateFamilyMembership =
  (familyNumber: string, familyMembersDto: FamilyMembersDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.updateFamilyMemberRoles(familyNumber, familyMembersDto);
      dispatch({
        type: UPDATE_FAMILY_MEMBERSHIP,
        payload: familyMembersDto,
      });
      dispatch(thunkCreateSuccessNotification("Die Familienberechtigungen wurden geändert"));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to change family permission", e));
      return false;
    }
  };

export const thunkTransferFamilyMember =
  (familyNumber: string, customerNumber: string): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.transferFamilyMember(familyNumber, customerNumber);
      dispatch({
        type: JOIN_FAMILY,
        payload: customerNumber,
      });
      dispatch(thunkCreateSuccessNotification("Das Mitglied wurde erfolgreich der Familie hinzugefügt"));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to change family permission", e));
      return false;
    }
  };

export const thunkInviteFamilyMember =
  (invitation: InvitationDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.inviteFamilyMember(invitation);
      dispatch(thunkCreateSuccessNotification(`Familienmitglied eingeladen: ${invitation.mailAddress}`));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to invite a family member", e));
      return false;
    }
  };
