import { Button, Grid, useTheme } from "@mui/material";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkGetGdprTemplates, thunkUpdateGdprTemplates } from "../../../store/actions/GdprActions";
import { IStore } from "../../../store/IStore";
import { GdprTemplates } from "../../../store/models/gdpr/GdprTemplate";
import { FormInput } from "../../atoms/FormInput";

export const gdprTemplateItems = [
  { key: "PROCESSING_PURPOSE", sequenceNumber: 2 },
  { key: "DATA_CATEGORIES", sequenceNumber: 3 },
  { key: "DATA_RECEIVER", sequenceNumber: 4 },
  { key: "STORAGE_PERIOD", sequenceNumber: 5 },
  { key: "INFORMATIONAL_RIGHTS", sequenceNumber: 6 },
  { key: "COMPLAINT_RIGHTS", sequenceNumber: 7 },
  { key: "DATA_SOURCE", sequenceNumber: 8 },
  { key: "AUTOMATED_DECISION", sequenceNumber: 9 },
  { key: "TRANSFER_TO_THIRD_COUNTRIES", sequenceNumber: 10 },
  { key: "SALUTATION", sequenceNumber: 11 },
];

export const useGdprTemplateItems = () => {
  const { t } = useTranslation();
  return [
    { title: t("setup.processingPurpose"), key: "PROCESSING_PURPOSE", sequenceNumber: 2 },
    { title: t("setup.dataCategories"), key: "DATA_CATEGORIES", sequenceNumber: 3 },
    { title: t("setup.dataRecipients"), key: "DATA_RECEIVER", sequenceNumber: 4 },
    { title: t("setup.retentionPeriod"), key: "STORAGE_PERIOD", sequenceNumber: 5 },
    { title: t("setup.dataSubjectRights"), key: "INFORMATIONAL_RIGHTS", sequenceNumber: 6 },
    { title: t("setup.complaintRight"), key: "COMPLAINT_RIGHTS", sequenceNumber: 7 },
    { title: t("setup.sourceOfData"), key: "DATA_SOURCE", sequenceNumber: 8 },
    { title: t("setup.automatedDecisionMaking"), key: "AUTOMATED_DECISION", sequenceNumber: 9 },
    { title: t("setup.transferToThirdCountries"), key: "TRANSFER_TO_THIRD_COUNTRIES", sequenceNumber: 10 },
    { title: t("setup.salutation"), key: "SALUTATION", sequenceNumber: 11 },
  ];
};

const Gdpr = (props: ThunkProps) => {
  const theme = useTheme();
  const { gdprTemplates } = props;
  const { t } = useTranslation();
  const gdprTemplateItems = useGdprTemplateItems();

  if (!gdprTemplates) {
    props.thunkGetGdprTemplates();
    return null;
  }

  const submit = (values: Record<string, string>) => {
    const gdprTemplates: GdprTemplates = {
      gdprTemplateItems: gdprTemplateItems.map((item) => {
        const value = Object.entries(values).find((value) => value[0] === item.key);
        return {
          key: item.key,
          value: value ? value[1] : undefined,
          sequenceNumber: item.sequenceNumber,
        };
      }),
    };
    props.thunkUpdateGdprTemplates(gdprTemplates);
  };

  const initialValues: Record<string, string | undefined> = {};
  gdprTemplates?.gdprTemplateItems.forEach((item) => (initialValues[item.key] = item.value));

  return (
    <Form
      onSubmit={submit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          {gdprTemplateItems.map((block) => (
            <div key={block.key} style={{ paddingBottom: theme.spacing(4) }}>
              <b>
                {block.title}
                <br />
              </b>
              <FormInput
                type="textarea"
                name={block.key}
                label=""
                variant="outlined"
                fullWidth={true}
                multiline={true}
              />
            </div>
          ))}
          <Grid container justifyContent="flex-end">
            <Button
              color="primary"
              type="submit"
              variant="contained"
              disabled={submitting}
              style={{ margin: theme.spacing(2, 0, 2, 2) }}
            >
              {t("setup.buttonSave")}
            </Button>
          </Grid>
        </form>
      )}
    />
  );
};

const mapStateToProps = (state: IStore) => ({
  gdprTemplates: state.gdpr.gdprTemplates,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkGetGdprTemplates, thunkUpdateGdprTemplates }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(Gdpr);
