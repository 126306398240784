import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Grid, Paper, Typography, useTheme } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkGetDashboardKpis } from "../../store/actions/DashboardActions";
import { IStore } from "../../store/IStore";
import { COLOR_BLACK, COLOR_GRAY_1 } from "../atoms/ImsMaterialTheme";
import BonusBurn from "./BonusBurn";
import BonusChart from "./BonusChart";
import BonusEarn from "./BonusEarn";
import { useTranslation } from "react-i18next";

function getTodayLocalTime(): Date {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

function getPrevDateFromDateString(dateString: string) {
  const date = new Date(dateString);
  date.setDate(date.getDate() - 1);
  return date;
}

function getNextDateFromDateString(dateString: string) {
  const date = new Date(dateString);
  date.setDate(date.getDate() + 1);
  return date;
}

export function isToday(dateString: string): boolean {
  return getTodayLocalTime().getTime() === new Date(dateString).getTime();
}
const BonusPanel = (props: ThunkProps) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const { interactionKpiDay } = props;

  if (!interactionKpiDay) {
    props.thunkGetDashboardKpis(getTodayLocalTime());
    return null;
  }
  return (
    <>
      <Paper
        style={{
          padding: theme.spacing(3),
          paddingBottom: theme.spacing(6),
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(6),
        }}
      >
        <Grid item xs={12} style={{ marginBottom: theme.spacing(3) }}>
          <Typography variant="h3">{t('dashboard.interactionToday')}</Typography>
        </Grid>
        <Grid item md={12} style={{ display: "flex", alignItems: "center" }}>
          <Grid item>
            <ArrowBackIos
              style={{ cursor: "pointer" }}
              onClick={() => {
                const prevDate = getPrevDateFromDateString(interactionKpiDay.date);
                props.thunkGetDashboardKpis(prevDate);
              }}
            />
          </Grid>
          <Grid item md>
            <BonusChart />
          </Grid>
          <Grid item>
            <ArrowForwardIos
              onClick={() => {
                const nextDate = getNextDateFromDateString(interactionKpiDay.date);
                !isToday(interactionKpiDay.date) && props.thunkGetDashboardKpis(nextDate);
              }}
              style={{
                color: !isToday(interactionKpiDay.date) ? COLOR_BLACK : COLOR_GRAY_1,
                cursor: !isToday(interactionKpiDay.date) ? "pointer" : undefined,
              }}
            />
          </Grid>
          <Grid
            item
            md={3}
            style={{
              marginLeft: theme.spacing(1),
            }}
          >
            <Grid
              item
              md
              style={{
                paddingBottom: "25%",
              }}
            >
              <BonusEarn
                bonusPoints={interactionKpiDay.bonusPointsEarnedTotal}
                interactions={interactionKpiDay.earnInteractionsCountTotal}
              />
            </Grid>
            <Grid item md>
              <BonusBurn
                bonusPoints={interactionKpiDay.bonusPointsBurnedTotal}
                interactions={interactionKpiDay.burnInteractionsCountTotal}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

const mapStateToProps = (state: IStore) => ({
  interactionKpiDay: state.dashboard.interactionKpiDay,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ thunkGetDashboardKpis }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(BonusPanel);
