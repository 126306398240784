import { Grid, Paper, useTheme } from "@mui/material";
import { CircleDto } from "../../store/models/circle/CircleDto";
import { FormSelect } from "../atoms/FormSelect";
import ImsPaperHead from "../atoms/ImsPaperHead";
import { nameof } from "../atoms/Utils";
import { getLabelText, RegistrationFormThunkProps } from "./RegistrationForm";
import { FormRadioButton } from "../atoms/FormRadioButton";
import { useCustomerTypeOptions } from "../../store/models/customer/CustomerType";
import { RegistrationDto } from "../../store/models/registration/RegistrationDto";
import { useTranslation } from "react-i18next";

const Registration = (props: RegistrationFormThunkProps) => {
  const theme = useTheme();
  const { circles, customer, registrationFieldConfig } = props;
  const { t } = useTranslation();
  const options = useCustomerTypeOptions();
  return (
    <Paper
      id="personal"
      style={{
        padding: theme.spacing(3),
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
      }}
    >
      <ImsPaperHead text={t("registration.headlineProgram")} />
      <Grid container spacing={2} style={{ paddingBottom: theme.spacing(4) }}>
        <Grid item md={2}>
          {
            <FormSelect
              name={nameof<CircleDto>("circleNumber")}
              label={getLabelText(t("registration.circle"), nameof<CircleDto>("circleName"), registrationFieldConfig)}
              options={[{ label: t("registration.inputPlaceHolder"), value: "" }].concat(
                circles.map((c) => ({
                  label: c.circleName,
                  value: c.circleNumber,
                }))
              )}
              fullWidth={true}
            />
          }
        </Grid>
        <Grid item sm={12}>
          <FormRadioButton
            row
            name={nameof<RegistrationDto>("customerType")}
            value={customer?.customerType}
            options={options}
            fieldConfig={registrationFieldConfig}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Registration;
