import { Delete, GetApp, Publish } from "@mui/icons-material";
import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField, useTheme } from "@mui/material";
import arrayMutators from "final-form-arrays";
import { ChangeEvent, SetStateAction, useMemo, useState } from "react";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../store/IStore";
import { v4 as uuid } from "uuid";
import { thunkAddBonusRuleFilter, thunkUpdateBonusRuleFilter } from "../../store/actions/BonusRuleActions";
import {
  BonusRuleDto,
  FilterDto,
  RuleFilterApplyType,
  useRuleFilterApplyTypeOptions,
} from "../../store/models/bonusRule/BonusRuleDto";
import { ConfigValueDto } from "../../store/models/config/ConfigDto";
import { FormRadioButton } from "../atoms/FormRadioButton";
import { FormSelect } from "../atoms/FormSelect";
import { isBlank, nameof, ValidationErrors } from "../atoms/Utils";
import NotificationList from "../notification/NotificationList";
import { FilterValueList } from "./FilterValueList";
import { BackdropProcessing } from "../app/BackdropProcessing";
import { useTranslation } from "react-i18next";

interface FilterFormProps {
  editFilterNumber: string;
  setEditFilterNumber: React.Dispatch<SetStateAction<string | undefined>>;
  bonusRule: BonusRuleDto;
  initialRuleFilterValues?: string[];
}

interface FilterFormDto extends FilterDto {
  configValue?: string;
}

const FilterForm = (props: FilterFormProps & ThunkProps) => {
  const theme = useTheme();
  const [processing, setProcessing] = useState<boolean>(false);
  const [newFilterNumberInitial] = useState<string>(uuid());
  const configGroups = props.configGroups.filter((obj) => {
    return ["RECEIPT_DETAIL", "CUSTOMER_PROPERTY"].includes(obj.key);
  });
  const originalCurrentBonusRule = props.originalBonusRules.find(
    (bonusRule) => bonusRule.ruleNumber === props.bonusRule.ruleNumber
  );
  const indexOfSavedFilter = originalCurrentBonusRule
    ? originalCurrentBonusRule.ruleFilters.findIndex((f) => f.ruleFilterNumber === props.editFilterNumber)
    : -1;
  const isSavedFilter = indexOfSavedFilter !== -1;

  const saveFilter = async (filter: FilterFormDto) => {
    const configValue = JSON.parse(filter.configValue!) as ConfigValueDto;
    filter.ruleFilterPropertyName = configValue.key;
    filter.ruleFilterType = configGroups.find((configGroup) =>
      configGroup.values.find((value) => value.key === configValue.key)
    )?.key;

    props.bonusRule.ruleFilters = props.bonusRule.ruleFilters || [];

    if (props.bonusRule?.ruleNumber) {
      setProcessing(true);
      const formData = new FormData();
      const csvBlob = new Blob([...filter.ruleFilterValues.join("\n")], {
        type: "text/csv",
      });
      formData.append("file", csvBlob, `${props.editFilterNumber}.csv`);
      formData.append(
        "data",
        JSON.stringify({
          ruleFilterNumber: newFilterNumberInitial,
          ruleFilterType: filter.ruleFilterType,
          ruleFilterPropertyName: filter.ruleFilterPropertyName,
          ruleFilterApplyType: filter.ruleFilterApplyType,
        })
      );
      let success;
      if (isSavedFilter) {
        success = await props.thunkUpdateBonusRuleFilter(formData, props.editFilterNumber);
      } else {
        success = await props.thunkAddBonusRuleFilter(formData, props.bonusRule.ruleNumber);
      }

      setProcessing(false);
      if (!success) {
        return;
      }
    } else {
      delete filter.configValue;
      const index = props.bonusRule.ruleFilters.findIndex((f) => f.ruleFilterNumber === filter.ruleFilterNumber);
      if (index !== -1) {
        props.bonusRule.ruleFilters[index] = filter;
      } else {
        props.bonusRule.ruleFilters.push(filter);
      }
    }
    props.setEditFilterNumber(undefined);
  };

  const { t } = useTranslation();
  const validateForm = (filter: FilterFormDto) => {
    const errors: ValidationErrors<FilterFormDto> = {};
    if (isBlank(filter.configValue)) {
      errors.configValue = t("messages.validation.required");
    }
    if (!filter.ruleFilterValues?.length) {
      errors.ruleFilterValues = t("messages.validation.required");
    }

    return errors;
  };

  const uploadFilterValues = async (e: ChangeEvent<HTMLInputElement>, concat: any, resetFilterValues: any) => {
    if (!e.target.files?.length) return;
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      if (!evt?.target?.result) {
        return;
      }
      const { result } = evt.target;
      const records = result
        .toString()
        .replace("\t", "")
        .replace("\r", "")
        .split("\n")
        .filter((item) => !!item && item.trim().length > 0)
        .map((item) => item.trim());
      resetFilterValues();
      concat(nameof<FilterDto>("ruleFilterValues"), records);
    };
    reader.readAsBinaryString(file);
  };

  const downloadFilterValues = (filterValues: string[]) => {
    const element = document.createElement("a");
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(filterValues.join("\n")));
    element.setAttribute("download", "nummern.csv");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const initialValues: FilterFormDto = useMemo(() => {
    const currentValues = props.bonusRule.ruleFilters?.find(
      (filter) => filter.ruleFilterNumber === props.editFilterNumber
    ) || {
      ruleFilterName: props.editFilterNumber,
      ruleFilterNumber: props.editFilterNumber,
      ruleFilterApplyType: RuleFilterApplyType.INCLUDE,
      ruleFilterValues: [],
    };
    return props.initialRuleFilterValues && props.initialRuleFilterValues.length > 0
      ? { ...currentValues, ruleFilterValues: props.initialRuleFilterValues }
      : currentValues;
  }, [props.bonusRule, props.editFilterNumber, props.initialRuleFilterValues]);
  initialValues.configValue = JSON.stringify(
    configGroups
      .map((configGroup) => configGroup.values)
      .flat()
      .find((value) => value.key === initialValues.ruleFilterPropertyName)
  );

  const [newFilterValue, setNewFilterValue] = useState("");
  const [selectedFilterValues, setSelectedFilterValues] = useState<string[]>([]);
  const [filterSearchInput, setFilterSearchInput] = useState("");
  const [filterSearchValue, setFilterSearchValue] = useState("");
  const options = useRuleFilterApplyTypeOptions();

  return (
    <Dialog fullWidth maxWidth="md" onClose={() => props.setEditFilterNumber(undefined)} open={true}>
      <BackdropProcessing processing={processing}></BackdropProcessing>
      <NotificationList isDialog />
      <DialogTitle>{isSavedFilter ?
          t("bonusRuleFilter.headline", { verb: t("bonusRuleFilter.verbEdit") }) : t("bonusRuleFilter.headline", { verb: t("bonusRuleFilter.verbCreate") })}
      </DialogTitle>
      <DialogContent>
        <Form
          onSubmit={saveFilter}
          mutators={{
            ...arrayMutators,
            resetFilterValues: (_, state, { changeValue }) => {
              changeValue(state, nameof<FilterDto>("ruleFilterValues"), () => []);
            },
          }}
          initialValues={initialValues}
          validate={validateForm}
          render={({
            handleSubmit,
            submitting,
            values,
            form: {
              mutators: { push, removeBatch, concat, resetFilterValues },
            },
          }) => {
            const addFilterValue = () => {
              if (newFilterValue && !values.ruleFilterValues.some((filterValues) => filterValues === newFilterValue)) {
                push(nameof<FilterDto>("ruleFilterValues"), newFilterValue);
              }
              setNewFilterValue("");
            };

            const removeSelectedValues = () => {
              removeBatch(
                nameof<FilterDto>("ruleFilterValues"),
                selectedFilterValues.map((value) => values.ruleFilterValues.indexOf(value))
              );
              setSelectedFilterValues([]);
            };

            return (
              <form
                onSubmit={handleSubmit}
                onKeyDown={(e) => {
                  if (e.key === "Enter") e.preventDefault();
                }}
              >
                <Grid container spacing={6}>
                  <Grid item md={12}>
                    <FormSelect
                      options={configGroups
                        .map((configGroup) =>
                          configGroup.values.map((value) => ({
                            label: value.description,
                            value: JSON.stringify(value),
                          }))
                        )
                        .flat()}
                      name={nameof<FilterFormDto>("configValue")}
                      label={t("bonusRuleFilter.filter")}
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item md={8}>
                    <TextField
                      id="outlined-search"
                      label={t("bonusRuleFilter.searchFilter")}
                      type="search"
                      onChange={(e) => setFilterSearchInput(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setFilterSearchValue(filterSearchInput);
                          return false;
                        }
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4}></Grid>
                  <Grid item md={8}>
                    <FilterValueList
                      filterValues={values.ruleFilterValues}
                      selectedFilterValues={selectedFilterValues}
                      setSelectedFilterValues={setSelectedFilterValues}
                      filterSearchValue={filterSearchValue}
                    />
                  </Grid>
                  <Grid item container md={4} direction="column">
                    <Grid item sx={{ marginBottom: theme.spacing(2) }}>
                      <FormRadioButton
                        row
                        options={options}
                        name={nameof<FilterFormDto>("ruleFilterApplyType")}
                        label={t("bonusRuleFilter.filterType")}
                        value={values.ruleFilterApplyType}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        style={{ justifyContent: "flex-start" }}
                        variant="contained"
                        color="primary"
                        onClick={removeSelectedValues}
                        fullWidth
                      >
                        <Delete /> {t("bonusRuleFilter.deleteSelected")}
                      </Button>
                    </Grid>
                    <Grid item style={{ marginTop: theme.spacing(2) }}>
                      <Button
                        style={{ justifyContent: "flex-start" }}
                        variant="contained"
                        color="primary"
                        onClick={resetFilterValues}
                        fullWidth
                      >
                        <Delete /> {t("bonusRuleFilter.deleteAll")}
                      </Button>
                    </Grid>
                    <Grid item style={{ marginTop: theme.spacing(2) }}>
                      <Button
                        style={{ justifyContent: "flex-start" }}
                        variant="contained"
                        component="label"
                        color="primary"
                        fullWidth
                      >
                        <Publish /> {t("bonusRuleFilter.uploadFile")}
                        <input
                          type="file"
                          accept=".csv"
                          hidden
                          onChange={(e) => uploadFilterValues(e, concat, resetFilterValues)}
                        />
                      </Button>
                      <Grid item style={{ marginTop: theme.spacing(2) }}>
                        <Button
                          style={{ justifyContent: "flex-start" }}
                          variant="contained"
                          component="label"
                          color="primary"
                          fullWidth
                          onClick={() => downloadFilterValues(values.ruleFilterValues)}
                        >
                          <GetApp /> {t("bonusRuleFilter.downloadFile")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item md={8}>
                    <TextField
                      variant="outlined"
                      onChange={(e) => setNewFilterValue(e.target.value)}
                      value={newFilterValue}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          addFilterValue();
                          return false;
                        }
                      }}
                      fullWidth
                    ></TextField>
                  </Grid>
                  <Grid item md={4} container alignItems="center">
                    <Button variant="contained" color="primary" onClick={addFilterValue} fullWidth>
                      {t("bonusRuleFilter.add")}
                    </Button>
                  </Grid>
                </Grid>

                <Grid container justifyContent="flex-end">
                  <Button
                    color="secondary"
                    type="button"
                    onClick={() => props.setEditFilterNumber(undefined)}
                    variant="contained"
                    style={{ margin: theme.spacing(2, 0, 2, 2) }}
                  >
                    {t("common.cancel")}
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    style={{ margin: theme.spacing(2, 0, 2, 2) }}
                  >
                    {t("common.save")}
                  </Button>
                </Grid>
              </form>
            );
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state: IStore) => ({
  configGroups: state.configGroups.configGroups,
  originalBonusRules: state.bonusRules.bonusRules,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkUpdateBonusRuleFilter, thunkAddBonusRuleFilter }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(FilterForm);
