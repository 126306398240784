import i18n from "i18next";

export enum PassIdView {
  BARCODE = "CODE128",
  QR = "QR",
}

export const passIdViewOptions = [
  { value: PassIdView.BARCODE, label: i18n.t("setup.barcode") },
  { value: PassIdView.QR, label: i18n.t("setup.qrCode") },
];
