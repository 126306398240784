import { TrendingUp } from "@mui/icons-material";
import { Grid, Typography, useTheme } from "@mui/material";
import { COLOR_GRAY_2, KNISTR_BLUE, KNISTR_PLUM } from "../atoms/ImsMaterialTheme";
import { formatNumber } from "../atoms/Utils";
import { useTranslation } from "react-i18next";

interface BonusEarnProps {
  bonusPoints: number;
  interactions: number;
}
const BonusEarn = (props: BonusEarnProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { bonusPoints, interactions } = props;

  return (
    <>
      <Grid
        item
        md
        style={{
          borderLeftWidth: "2px",
          borderLeftStyle: "solid",
          borderLeftColor: KNISTR_PLUM,
          paddingLeft: theme.spacing(1),
        }}
        container
        direction="column"
        justifyContent="space-between"
      >
        <Grid item md={12} style={{ display: "flex", alignItems: "center" }}>
          <TrendingUp fontSize="large" />
          <Typography style={{ paddingLeft: theme.spacing(1) }}>Bonus Earn</Typography>
        </Grid>
        <Grid item md={12} style={{ display: "flex", alignItems: "baseline" }}>
          <Typography
            style={{
              color: KNISTR_BLUE,
              paddingLeft: theme.spacing(1),
              fontWeight: "bold",
              fontSize: "32px",
              paddingRight: theme.spacing(1),
            }}
          >
            {formatNumber(bonusPoints)}
          </Typography>
          <Typography variant="subtitle1" style={{ color: COLOR_GRAY_2, fontWeight: "bold" }}>
            {t("dashboard.point")}
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="subtitle1" style={{ color: COLOR_GRAY_2, fontWeight: "bold" }}>
        {t("dashboard.interactionsX", { number: formatNumber(interactions) })}
      </Typography>
    </>
  );
};

export default BonusEarn;
