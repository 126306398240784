import { MoreVert } from "@mui/icons-material";
import { IconButton, Link, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathCampaignEdit } from "../../api/url";
import { thunkDisableCampaign, thunkEnableCampaign, thunkFindAllCampaigns } from "../../store/actions/CampaignActions";
import { canMaintainCampaigns } from "../../store/actions/LoginActions";
import { IStore } from "../../store/IStore";
import { CampaignDto } from "../../store/models/campaign/CampaignDto";
import { CampaignStatus } from "../../store/models/campaign/CampaignStatus";
import { DateTag } from "../atoms/DateTag";
import { ImsHeadCell, ImsTable, ImsTableRow } from "../atoms/ImsTable";
import { formatGermanDate, getCurrentDateAsString } from "../atoms/Utils";
import { useTranslation } from "react-i18next";

const CampaignTable = (props: ThunkProps) => {
  const { t } = useTranslation();

  const headCells: ImsHeadCell<CampaignDto>[] = [
    { val: (val) => val.campaignName, node: t("campaigns.tableHeadlineName") },
    { val: (val) => val.startDate, node: t("campaigns.tableHeadlineAusspielung") },
    { val: (val) => val.couponDetails.unitNumber, node: t("campaigns.tableHeadlineOperationalUnit") },
    { val: (val) => val.endDate, node: t("campaigns.tableHeadlineValidity") },
    { val: (val) => val.couponDetails.advantageInfo, node: t("campaigns.tableHeadlineCoupon") },
  ];

  const enableCampaign = (campaign: CampaignDto) => async () => {
    await props.thunkEnableCampaign(campaign);
    await props.thunkFindAllCampaigns();
  };

  const disableCampaign = (campaign: CampaignDto) => async () => {
    await props.thunkDisableCampaign(campaign);
    await props.thunkFindAllCampaigns();
  };

  const rows: ImsTableRow<CampaignDto>[] = props.campaigns.map((campaign) => {
    const operationalUnitName = props.operationalUnits.find(
      (operationalUnit) => operationalUnit.unitNumber === campaign.couponDetails.unitNumber
    )?.unitName;

    let expiryDisplay = "";

    if (campaign.couponDetails.expiryMonths) {
      expiryDisplay = `${campaign.couponDetails.expiryMonths} ${t("campaigns.months")}`;
    } else if (campaign.couponDetails.expiryDate) {
      expiryDisplay = `${t("campaigns.until")} ${formatGermanDate(campaign.couponDetails.expiryDate)}`;
    }

    return {
      value: { ...campaign },
      nodes: [
        <b>{campaign.campaignName}</b>,
        <>
          <DateTag date={campaign.startDate} />
          {campaign.endDate ? t("campaigns.fromToSeparator") : ""}
          <DateTag date={campaign.endDate} />
        </>,
        operationalUnitName,
        <>{expiryDisplay}</>,
        campaign.couponDetails.advantageInfo,
        <CampaignOverviewMenu
          campaign={campaign}
          handleEnable={enableCampaign(campaign)}
          handleDisable={disableCampaign(campaign)}
        />,
      ],
      disabled: campaign.campaignStatus === CampaignStatus.DISABLED,
    };
  });
  return <ImsTable hasMenu={true} headCells={headCells} rows={rows} />;
};

interface CampaignOverviewMenuProps {
  campaign: CampaignDto;
  handleEnable: () => void;
  handleDisable: () => void;
}

const CampaignOverviewMenu = (props: CampaignOverviewMenuProps) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const open = Boolean(anchorEl);

  if (!canMaintainCampaigns()) return null;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(undefined);
    event.stopPropagation();
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {props.campaign.campaignStatus === CampaignStatus.DISABLED && (
          <MenuItem
            data-testid="test-btn-activate"
            onClick={(e) => {
              props.handleEnable();
              handleClose(e);
            }}
          >
            {t("campaigns.kebabActivateMenu")}
          </MenuItem>
        )}
        {props.campaign.campaignStatus === CampaignStatus.ENABLED &&
          (!props.campaign.endDate || props.campaign.endDate < getCurrentDateAsString()) && (
            <MenuItem
              data-testid="test-btn-deactivate"
              onClick={(e) => {
                props.handleDisable();
                handleClose(e);
              }}
            >
              {t("campaigns.kebabDeactivateMenu")}
            </MenuItem>
          )}

        <MenuItem onClick={handleClose}>
          <Link component={RouterLink} to={UrlPathCampaignEdit.replace(":id", props.campaign.campaignNumber)}>
            {t("campaigns.kebabEditMenu")}
          </Link>
        </MenuItem>
      </Menu>
    </div>
  );
};

const mapStateToProps = (state: IStore) => ({
  campaigns: state.campaigns.campaigns,
  operationalUnits: state.operationalUnits.operationalUnits,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkFindAllCampaigns, thunkEnableCampaign, thunkDisableCampaign }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(CampaignTable);
