import { Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { UrlPathCampaignCreate } from "../../api/url";
import { canMaintainCampaigns } from "../../store/actions/LoginActions";
import MenuLayout from "../app/MenuLayout";
import CampaignTable from "./CampaignTable";
import { useTranslation } from "react-i18next";

export const CampaignPage = () => {
  const { t } = useTranslation();
  return (
    <MenuLayout headline={t("campaigns.title")}>
      <Grid container spacing={2}>
        {canMaintainCampaigns() && (
          <Grid container item justifyContent="flex-end">
            <Button component={Link} to={UrlPathCampaignCreate} startIcon="+" variant="contained" color="primary">
              {t("campaigns.buttonNewCampaign")}
            </Button>
          </Grid>
        )}
        <Grid item md={12}>
          <CampaignTable />
        </Grid>
      </Grid>
    </MenuLayout>
  );
};
