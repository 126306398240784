import MenuLayout from "../app/MenuLayout";
import RegistrationForm from "./RegistrationForm";
import { useTranslation } from "react-i18next";

export const RegistrationPage = () => {
  const { t } = useTranslation();
  return (
    <MenuLayout headline={t("registration.pageTitle")}>
      <RegistrationForm />
    </MenuLayout>
  );
};
