import { useTranslation } from "react-i18next";

export enum CustomerType {
  INDIVIDUAL = "INDIVIDUAL",
  CORPORATE = "CORPORATE",
  UNKNOWN = "UNKNOWN",
}

export const useCustomerTypeOptions = () => {
  const { t } = useTranslation();

  return [
    {
      label: t("registration.private"),
      value: CustomerType.INDIVIDUAL,
    },
    {
      label: t("registration.commercial"),
      value: CustomerType.CORPORATE,
    },
  ];
};
