import { Api } from "../../api/Api";
import { BooleanThunk } from "../IStore";
import { thunkCreateErrorNotification } from "./NotificationActions";

export const GET_ALL_EIN_GENERATOR_TEMPLATES = "GET_ALL_EIN_GENERATOR_TEMPLATES";

export const thunkGetAllEinGeneratorTemplates = (): BooleanThunk => async (dispatch) => {
  try {
    const einGeneratorTemplates = await Api.getEinGeneratorTemplates();
    dispatch({
      type: GET_ALL_EIN_GENERATOR_TEMPLATES,
      payload: einGeneratorTemplates,
    });
    return true;
  } catch (e) {
    dispatch(thunkCreateErrorNotification("Failed to load pass config", e));
    return false;
  }
};
