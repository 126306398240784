import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { IStore } from "../../store/IStore";
import { BonusPremiumDto } from "../../store/models/bonusPremium/BonusPremiumDto";
import { CouponAdvantageType } from "../../store/models/bonusPremium/CouponAdvantageType";
import { CampaignDto } from "../../store/models/campaign/CampaignDto";
import { CampaignStatus } from "../../store/models/campaign/CampaignStatus";
import { CampaignType } from "../../store/models/campaign/CampaignType";
import MenuLayout from "../app/MenuLayout";
import CampaignForm from "./CampaignForm";
import { useTranslation } from "react-i18next";

const defaultCampaign: CampaignDto = {
  campaignName: "",
  campaignNumber: "",
  startDate: "",
  couponDetails: {
    advantageType: CouponAdvantageType.EURO,
    advantageInfo: "",
    generatorPrefix: "",
  },
  campaignType: CampaignType.FIXED_DAY,
  campaignStatus: CampaignStatus.ENABLED,
  selectionCriteria: {
    unitNumber: "",
  },
};

interface CampaignFormPageProps {
  campaigns?: CampaignDto[];
  bonusPremiums?: BonusPremiumDto[];
}

const CampaignFormPage = (props: CampaignFormPageProps) => {
  const { campaigns = [], bonusPremiums } = props;
  const { id } = useParams<"id">();
  const campaign = campaigns.find((campaign) => campaign.campaignNumber === id);
  const { t } = useTranslation();
  return (
    <MenuLayout headline={id ? t("campaigns.editCampaignPageTitle") : t("campaigns.newCampaignPageTitle")}>
      <CampaignForm campaign={campaign || defaultCampaign} {...bonusPremiums} />
    </MenuLayout>
  );
};

const mapStateToProps = (state: IStore) => ({
  campaigns: state.campaigns.campaigns,
  bonusPremiums: state.bonusPremiums.bonusPremiums,
});

export default connect(mapStateToProps)(CampaignFormPage);
