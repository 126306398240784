import { Api } from "../../api/Api";
import { BooleanThunk, BooleanThunkAction } from "../IStore";
import { IdentificationTypeDto } from "../models/identification/IdentificationTypeDto";
import { thunkCreateErrorNotification, thunkCreateSuccessNotification } from "./NotificationActions";

export const GET_ALL_IDENTIFICATION_TYPES = "GET_ALL_IDENTIFICATION_TYPES";
export const DELETE_IDENTIFICATION_TYPE = "DELETE_IDENTIFICATION_TYPE";
export const GET_CUSTOMER_IDENTIFICATIONS = "GET_CUSTOMER_IDENTIFICATIONS";
export const GET_CUSTOMER_CUSTOM_FIELDS = "GET_CUSTOMER_CUSTOM_FIELDS";

export const thunkGetAllIdentificationTypes = (): BooleanThunk => async (dispatch) => {
  try {
    const identificationTypes = await Api.getAllIdentificationTypes();
    dispatch({
      type: GET_ALL_IDENTIFICATION_TYPES,
      payload: identificationTypes,
    });
    return true;
  } catch (e) {
    dispatch(thunkCreateErrorNotification("Failed to load passes", e));
    return false;
  }
};

export const thunkCreateIdentificationType =
  (identificationType: IdentificationTypeDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.createIdentificationType(identificationType);
      dispatch(thunkGetAllIdentificationTypes() as BooleanThunkAction);
      dispatch(thunkCreateSuccessNotification("Der Pass wurde angelegt"));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to create pass", e));
      return false;
    }
  };

export const thunkUpdateIdentificationType =
  (identificationType: IdentificationTypeDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.updateIdentificationType(identificationType);
      dispatch(thunkGetAllIdentificationTypes() as BooleanThunkAction);
      dispatch(thunkCreateSuccessNotification("Der Pass wurde aktualisiert"));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to update the pass", e));
      return false;
    }
  };

export const thunkDeleteIdentificationType =
  (identificationType: IdentificationTypeDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.deleteIdentificationType(identificationType);
      dispatch({
        type: DELETE_IDENTIFICATION_TYPE,
        payload: identificationType,
      });
      dispatch(thunkCreateSuccessNotification("Der Pass wurde gelöscht"));
      return true;
    } catch (e) {
      if (e === "The Default Identification is not deletable!") {
        dispatch(
          thunkCreateErrorNotification(
            "The pass cannot be deleted - The standard pass must not be deleted.",
            e
          )
        );
      } else if (e === "Identification in use by Customer.") {
        dispatch(
          thunkCreateErrorNotification(
            "The pass cannot be deleted - There are still members assigned to this pass.",
            e
          )
        );
      } else if (e === "Identification in use by Bonus Rule.") {
        dispatch(
          thunkCreateErrorNotification(
            "The pass cannot be deleted - There are still bonus rules associated with this pass.",
            e
          )
        );
      } else {
        dispatch(thunkCreateErrorNotification("Failed to delete the pass", e));
      }
    }
    return false;
  };

export const thunkGetCustomerIdentifications =
  (customerNumber: string): BooleanThunk =>
  async (dispatch) => {
    try {
      const identifications = await Api.getCustomerIdentifications(customerNumber);
      dispatch({
        type: GET_CUSTOMER_IDENTIFICATIONS,
        payload: identifications,
      });
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to load customer passes", e));
      return false;
    }
  };
