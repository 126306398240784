import { Box, Button, Grid, Paper, Typography, useTheme } from "@mui/material";
import { ValidationErrors } from "final-form";
import { debounce, isEqual } from "lodash";
import memoizeOne from "memoize-one";
import { useState } from "react";
import { AnyObject, Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { Navigate } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathCampaigns } from "../../api/url";
import {
  thunkCreateCampaign,
  thunkGetCustomerCountForCampaign,
  thunkUpdateCampaign,
} from "../../store/actions/CampaignActions";
import { IStore } from "../../store/IStore";
import { CouponAdvantageType } from "../../store/models/bonusPremium/CouponAdvantageType";
import { CampaignDto } from "../../store/models/campaign/CampaignDto";
import { CampaignFormModel } from "../../store/models/campaign/CampaignFormModel";
import { CampaignType } from "../../store/models/campaign/CampaignType";
import { FormDate } from "../atoms/FormDate";
import { FormInput } from "../atoms/FormInput";
import { FormRadioButton } from "../atoms/FormRadioButton";
import { FormSelect } from "../atoms/FormSelect";
import { COLOR_GRAY_1 } from "../atoms/ImsMaterialTheme";
import ImsPaperHead from "../atoms/ImsPaperHead";
import { getCurrentDateAsString, getToday, getTomorrow, isBlank, isPositiveInteger } from "../atoms/Utils";
import { getOperationalUnitOptions } from "../operationalUnit/OperationalUnitForm";
import { useTranslation } from "react-i18next";
import { getGenderOptions } from "../../store/models/common/Gender";

interface CampaignFormProps {
  campaign: CampaignDto;
}

const CampaignForm = (props: CampaignFormProps & ThunkProps) => {
  const theme = useTheme();
  const [redirect, setRedirect] = useState(false);
  const { t } = useTranslation();
  const genderOptions = getGenderOptions(t);
  const couponValidityTypeOptions = [
    {
      label: t("campaigns.invalidAtRadioButton"),
      value: "invalidAt",
    },
    {
      label: t("campaigns.invalidPeriodRadioButton"),
      value: "invalidPeriod",
    },
  ];
  const advantageTypeOptions = [
    { value: CouponAdvantageType.EURO, label: t("campaigns.moneyAmount") },
    { value: CouponAdvantageType.PERCENT, label: t("campaigns.percentage") },
    { value: CouponAdvantageType.OFFER, label: t("campaigns.present") },
  ];

  const campaignTypeOptions = [
    {
      label: t("campaigns.radioButtonCertainCay"),
      value: CampaignType.FIXED_DAY,
    },
    {
      label: t("campaigns.radioButtonBirthday"),
      value: CampaignType.DATE_OF_BIRTH_TIME_PERIOD,
    },
  ];
  const saveCampaign = async (campaignForm: CampaignFormModel) => {
    const campaign = mapFormModelToCampaign(campaignForm);
    const success = campaign.campaignNumber
      ? await props.thunkUpdateCampaign(campaign)
      : await props.thunkCreateCampaign(campaign);
    if (success) {
      setRedirect(true);
    }
  };

  const searchDebounced = debounce(
    memoizeOne(props.thunkGetCustomerCountForCampaign, (newArgs, lastArgs) => isEqual(newArgs[0], lastArgs[0])),
    300
  );

  const isInvalidAfterPeriod = (values: CampaignFormModel) => {
    if (values.couponValidityType === "invalidPeriod") {
      delete values.couponDetails.expiryDate;
      return true;
    } else {
      delete values.couponDetails.expiryMonths;
      return false;
    }
  };

  if (redirect) {
    return <Navigate to={UrlPathCampaigns} />;
  }
  const { campaign, operationalUnits, customerCountForCampaign } = props;

  if (!operationalUnits.length) return null;

  const operationalUnitOptions = getOperationalUnitOptions(undefined, operationalUnits, theme, true, false);
  return (
    <>
      <Form
        onSubmit={saveCampaign}
        initialValues={mapCampaignToFormModel(campaign)}
        keepDirtyOnReinitialize={true}
        validate={(values) => validateForm(values, t)}
        render={({ handleSubmit, submitting, values }) => {
          const isBirthday = values.campaignType === CampaignType.DATE_OF_BIRTH_TIME_PERIOD;
          const showDateRange = isBirthday || values.campaignType === CampaignType.TIME_PERIOD;
          searchDebounced(values.selectionCriteria);

          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid container item>
                  <Grid item md={12}>
                    <FormInput
                      type="text"
                      name="campaignName"
                      label={t("campaigns.campaignLabelName")}
                      variant="standard"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid item md={12}>
                    <FormInput
                      type="textarea"
                      fullWidth={true}
                      name="campaignDescription"
                      label={t("campaigns.campaignDescriptionLabel")}
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Paper
                style={{
                  padding: theme.spacing(3),
                  marginTop: theme.spacing(14),
                }}
              >
                <ImsPaperHead text={t("campaigns.campaignFormHeadLine")} />
                <Grid container spacing={4}>
                  <Grid container item>
                    <Grid item md={3}>
                      <FormRadioButton
                        name="campaignType"
                        label={t("campaigns.campaignType")}
                        value={values.campaignType}
                        options={campaignTypeOptions}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item md={12}>
                      <b>{showDateRange ? t("campaigns.labelSelectPeriod") : t("campaigns.labelSelectDate")}</b>
                    </Grid>
                    <Grid item md={4}>
                      <FormDate
                        name="startDate"
                        label={t("campaigns.labelDate")}
                        prefix={showDateRange ? t("campaigns.labelDateFrom") : t("campaigns.labelDateOn")}
                        minDate={getToday()}
                        testId="dt-sd"
                      />
                    </Grid>
                    {showDateRange && (
                      <Grid item md={4}>
                        <FormDate
                          name="endDate"
                          label={t("campaigns.labelDate")}
                          prefix={t("campaigns.upTo")}
                          testId="dt-ed"
                          helperText="Optional"
                          minDate={getToday()}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Paper>
              <Paper
                style={{
                  padding: theme.spacing(3),
                  marginTop: theme.spacing(8),
                }}
              >
                <ImsPaperHead text={t("campaigns.campaignFormSecondHeadLine")} />
                <Grid container spacing={4}>
                  <Grid container item>
                    <Grid item md={4}>
                      <b>{t("campaigns.customersGridTitle")}</b>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={3}>
                    <Grid item md={4}>
                      <FormSelect
                        label={t("campaigns.selectOperationalUnit")}
                        name="selectionCriteria.unitNumber"
                        options={operationalUnitOptions}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid container item>
                    <Grid item md={8}>
                      <b>{t("campaigns.customersSecondGridTitle")}</b>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={3}>
                    <Grid item md={4}>
                      <FormSelect
                        label={t("campaigns.genderLabel")}
                        name="selectionCriteria.gender"
                        options={genderOptions}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={3}>
                    <Grid item md={2}>
                      <FormInput
                        type="number"
                        label={t("campaigns.AgeFrom")}
                        name="selectionCriteria.minimumAge"
                        variant="outlined"
                        fullWidth={true}
                      />
                    </Grid>
                    <Grid item md={2}>
                      <FormInput
                        type="number"
                        label={t("campaigns.AgeTo")}
                        name="selectionCriteria.maximumAge"
                        variant="outlined"
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item md={12}>
                      <b>{t("campaigns.customersThirdGridTitle")}</b>
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item md={5}>
                      <FormInput
                        type="number"
                        name="selectionCriteria.minimumActivityCount"
                        label={t("campaigns.numberOfPurchases")}
                        variant="outlined"
                        prefix={t("campaigns.minLabel")}
                        fullWidth={true}
                        helperText="Optional"
                        tooltip={t("campaigns.minPurchaseTooltip")}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item md={5}>
                      <FormInput
                        type="number"
                        name="selectionCriteria.minimumBuyingCount"
                        label={t("campaigns.revenueLabel")}
                        variant="outlined"
                        prefix={t("campaigns.minLabel")}
                        fullWidth={true}
                        helperText="Optional"
                        tooltip={t("campaigns.minRevenueTooltip")}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item md={5}>
                      <FormInput
                        type="number"
                        name="selectionCriteria.minimumBonusPointsEarnedInTotal"
                        label={t("campaigns.bonusPointsLabel")}
                        variant="outlined"
                        prefix={t("campaigns.minLabel")}
                        fullWidth={true}
                        helperText="Optional"
                        tooltip={t("campaigns.minBonusPointTooltip")}
                      />
                    </Grid>
                  </Grid>

                  <Grid item md={12}>
                    <Box
                      style={{
                        backgroundColor: COLOR_GRAY_1,
                        padding: 20,
                        borderRadius: 5,
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="bottom"
                        style={{ marginBottom: theme.spacing(1) }}
                      >
                        <span style={{ paddingRight: theme.spacing(2) }}>{t("campaigns.customersGridInfo")}</span>
                      </Box>
                      <Typography variant="h2">{customerCountForCampaign}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
              <Paper
                style={{
                  padding: theme.spacing(3),
                  marginTop: theme.spacing(8),
                }}
              >
                <ImsPaperHead text={t("campaigns.campaignFormThirdHeadLine")} />

                <Grid container item spacing={4}>
                  <Grid item md={12}>
                    <b>{t("campaigns.couponNameLabel")}</b>
                  </Grid>
                  <Grid item md={5} style={{ marginBottom: theme.spacing(2) }}>
                    <FormInput
                      type="text"
                      name="couponDetails.advantageInfo"
                      label={t("campaigns.displayedNameLabel")}
                      fullWidth={true}
                      variant="outlined"
                      tooltip={t("campaigns.couponTextTooltip")}
                    />
                  </Grid>
                  <Grid container item>
                    <Grid item md={3}>
                      <FormRadioButton
                        value={values.couponDetails.advantageType}
                        name="couponDetails.advantageType"
                        label={t("campaigns.couponTypeLabel")}
                        options={advantageTypeOptions}
                      />
                    </Grid>
                  </Grid>

                  {values.couponDetails.advantageType === CouponAdvantageType.EURO && (
                    <Grid container item spacing={4}>
                      <Grid item md={12}>
                        <b>{t("campaigns.amountInEuro")}</b>
                      </Grid>
                      <Grid item md={4}>
                        <FormInput
                          type="number"
                          name="couponDetails.advantageValue"
                          label={t("campaigns.euroLabel")}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  )}
                  {values.couponDetails.advantageType === CouponAdvantageType.PERCENT && (
                    <Grid container item spacing={4}>
                      <Grid item md={12}>
                        <b>{t("campaigns.discountInPercent")}</b>
                      </Grid>
                      <Grid item md={4}>
                        <FormInput
                          type="number"
                          name="couponDetails.advantageValue"
                          label={t("campaigns.discount")}
                          fullWidth={true}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid container item>
                    <Grid item md={6}>
                      <FormRadioButton
                        name="couponValidityType"
                        value={values.couponValidityType}
                        label={t("campaigns.couponValidityLabel")}
                        options={couponValidityTypeOptions}
                      />
                    </Grid>
                  </Grid>
                  {isInvalidAfterPeriod(values) ? (
                    <Grid container item spacing={2}>
                      <Grid item md={12}>
                        <b>{t("campaigns.expirationPeriod")}</b>
                      </Grid>
                      <Grid item md={4}>
                        <FormInput type="number" name="couponDetails.expiryMonths" label="Monate" variant="outlined" />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container item>
                      <Grid item md={12}>
                        <b>{t("campaigns.ValidityDate")}</b>
                      </Grid>
                      <Grid item md={4}>
                        <FormDate
                          name="couponDetails.expiryDate"
                          label={t("campaigns.labelDate")}
                          prefix={t("campaigns.upTo")}
                          minDate={getTomorrow()}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid container item spacing={2}>
                    <Grid item md={12}>
                      <b>{t("campaigns.redeemedBranches")}</b>
                    </Grid>
                    <Grid item md={4}>
                      <FormSelect
                        label={t("campaigns.selectOperationalUnit")}
                        name="couponDetails.unitNumber"
                        options={operationalUnitOptions}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item md={12}>
                      <b>Coupon ID</b>
                    </Grid>
                    <Grid item md={4}>
                      <FormInput
                        type="text"
                        name="couponDetails.generatorPrefix"
                        label={t("campaigns.prefix")}
                        fullWidth={true}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
              <Grid container item justifyContent="flex-end">
                <Button
                  color="secondary"
                  type="button"
                  onClick={() => setRedirect(true)}
                  variant="contained"
                  style={{ margin: theme.spacing(2, 0, 2, 2) }}
                >
                  {t("common.cancel")}
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  data-testid="button-save"
                  variant="contained"
                  disabled={submitting}
                  style={{ margin: theme.spacing(2, 0, 2, 2) }}
                >
                  {values.campaignNumber ? t("campaigns.buttonSave") : t("campaigns.buttonCreate")}
                </Button>
              </Grid>
            </form>
          );
        }}
      />
    </>
  );
};

const validateForm = (values: CampaignFormModel, t: Function) => {
  const errors: ValidationErrors = {
    couponDetails: {},
    selectionCriteria: {},
  };
  if (isBlank(values.campaignName)) {
    errors.campaignName = t("campaigns.campaignNameMessage");
  }
  if (!values.selectionCriteria.unitNumber) {
    errors.selectionCriteria.unitNumber = t("campaigns.mandatoryField");
  }
  if (!values.campaignType) {
    errors.campaignType = t("campaigns.mandatoryField");
  }
  if (!values.startDate) {
    errors.startDate = t("campaigns.mandatoryField");
  } else if (values.startDate < getCurrentDateAsString()) {
    errors.startDate = t("campaigns.statDateMessage");
  }
  if (values.startDate && values.endDate && values.endDate < values.startDate) {
    errors.endDate = t("campaigns.endDateMessage");
  }
  return validateCouponDetails(values, errors, t);
};

function validateCouponDetails(values: CampaignFormModel, errors: AnyObject, t: Function) {
  if (!values.couponDetails.unitNumber) {
    errors.couponDetails.unitNumber = t("campaigns.mandatoryField");
  }
  if (isBlank(values.couponDetails.advantageInfo)) {
    errors.couponDetails.advantageInfo = t("campaigns.displayNameMessage");
  }

  if (
    values.couponDetails.expiryDate &&
    values.startDate &&
    values.couponDetails.expiryDate < values.startDate &&
    !values.endDate
  ) {
    errors.couponDetails.expiryDate = t("campaigns.couponExpiryDateMessage");
  }

  if (
    values.couponDetails.expiryDate &&
    values.startDate &&
    values.endDate &&
    values.couponDetails.expiryDate < values.endDate
  ) {
    errors.couponDetails.expiryDate = t("campaigns.couponExpiryDateSecondMessage");
  }

  if (values.couponDetails.advantageType !== CouponAdvantageType.OFFER && !values.couponDetails.advantageValue) {
    errors.couponDetails.advantageValue = t("campaigns.discountAmountMessage");
  }
  if (values.couponValidityType === "invalidAt" && !values.couponDetails.expiryDate) {
    errors.couponDetails.expiryDate = t("campaigns.mandatoryField");
  }
  if (values.couponValidityType === "invalidPeriod" && !values.couponDetails.expiryMonths) {
    errors.couponDetails.expiryMonths = t("campaigns.mandatoryField");
  }

  if (isBlank(values.couponDetails.generatorPrefix)) {
    errors.couponDetails.generatorPrefix = t("campaigns.mandatoryField");
  } else if (values.couponDetails.generatorPrefix.length > 6) {
    errors.couponDetails.generatorPrefix = t("campaigns.generatorPrefixMessage");
  }
  return errors;
}

const mapCampaignToFormModel = (campaign: CampaignDto): CampaignFormModel => {
  return {
    ...campaign,
    couponValidityType: isPositiveInteger(campaign.couponDetails.expiryMonths) ? "invalidPeriod" : "invalidAt",
  };
};

const mapFormModelToCampaign = (campaignForm: CampaignFormModel): CampaignDto => {
  const campaign = {
    ...campaignForm,
  };
  if (campaignForm.selectionCriteria.gender === "ANY") {
    delete campaignForm.selectionCriteria.gender;
  }

  return campaign;
};

const mapStateToProps = (state: IStore) => ({
  customerCountForCampaign: state.campaigns.customerCountForCampaign,
  operationalUnits: state.operationalUnits.operationalUnits,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkGetCustomerCountForCampaign,
      thunkCreateCampaign,
      thunkUpdateCampaign,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(CampaignForm);
