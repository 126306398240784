import {
  tokenRequestOptions,
  tokenRequestOptionsForMultipart,
  tokenRequestOptionsOneTime,
} from "../store/actions/LoginActions";
import { AdjustmentDto } from "../store/models/adjustment/AdjustmentDto";
import { BonusPremiumDto, BonusPremiumStatus } from "../store/models/bonusPremium/BonusPremiumDto";
import { BonusRuleDto, FilterDto } from "../store/models/bonusRule/BonusRuleDto";
import { BonusRuleStatus } from "../store/models/bonusRule/BonusRuleStatus";
import { BonusXpressDto } from "../store/models/bonusXpress/BonusXpressDto";
import { CampaignDto, CustomerCountForCampaignDto, SelectionCriteriaDto } from "../store/models/campaign/CampaignDto";
import { CampaignStatus } from "../store/models/campaign/CampaignStatus";
import { CircleDto } from "../store/models/circle/CircleDto";
import { ConfigDto, ConfigGroupDto, ConfigValueDto } from "../store/models/config/ConfigDto";
import { ConfigGroupKey } from "../store/models/config/ConfigGroupKey";
import { CouponDto } from "../store/models/coupon/CouponDto";
import { CustomerBonusPointsDto } from "../store/models/customer/CustomerBonusPointsDto";
import { CustomerCustomFieldDto } from "../store/models/customer/CustomerCustomFieldDto";
import { CustomerDto } from "../store/models/customer/CustomerDto";
import { CustomerSearchDto } from "../store/models/customer/CustomerSearchDto";
import { CustomerStatusDto } from "../store/models/customer/CustomerStatusDto";
import { MembershipCancellationDto } from "../store/models/customer/MembershipCancellationDto";
import { ReceiptDetailsDto } from "../store/models/customer/ReceiptDetailsDto";
import { InteractionKpiDayDto } from "../store/models/dashboard/InteractionKpiDayDto";
import { FamilyMembershipDto, FamilyMembershipRole } from "../store/models/family/FamilyMembershipDto";
import { ConfigTypes, FieldConfigDto } from "../store/models/fieldConfig/FieldConfigDto";
import { FlowDetailsDto, FlowGroupDto } from "../store/models/flow/FlowDto";
import { GdprRequestDto } from "../store/models/gdpr/GdprRequestDto";
import { GdprTemplates } from "../store/models/gdpr/GdprTemplate";
import { CustomerHistoryDto } from "../store/models/history/HistoryDto";
import { CustomerIdentificationDto } from "../store/models/identification/CustomerIdentificationDto";
import { IdentificationTypeDto } from "../store/models/identification/IdentificationTypeDto";
import { InteractionSearchDto } from "../store/models/interactionSearch/InteractionSearchDto";
import { InteractionSearchResponseDto } from "../store/models/interactionSearch/InteractionSearchResponseDto";
import { InvitationDto } from "../store/models/InvitationDto";
import { OperationalUnitDto } from "../store/models/operationalUnit/OperationalUnitDto";
import { OperationalUnitType } from "../store/models/operationalUnit/OperationalUnitType";
import { EinGeneratorTemplateDto } from "../store/models/eingeneratortemplate/EinGeneratorTemplateDto";
import { CustomerPreferenceDto } from "../store/models/preferences/CustomerPreferenceDto";
import { PreferenceDto } from "../store/models/preferences/PreferenceDto";
import { QuestionDto } from "../store/models/preferences/QuestionDto";
import { BranchStoreDto } from "../store/models/registration/BranchStoreDto";
import { RegistrationCustomerDto } from "../store/models/registration/RegistrationCustomerDto";
import { RegistrationDto } from "../store/models/registration/RegistrationDto";
import { ChangePasswordDto } from "../store/models/user/ChangePasswordDto";
import { UserDto } from "../store/models/user/UserDto";
import { fetchWithTenant } from "./TenantUtils";
import { ReplacementCardDto } from "../store/models/identification/ReplacementCardDto";
import { ReplacementIdentifactionDto } from "../store/models/identification/ReplacementIdentifactionDto";
import { CashbackDto } from "../store/models/cashback/CashbackDto";
import { FamilyDto } from "../store/models/family/FamilyDto";
import { CustomerNumberDto } from "../store/models/customer/CustomerNumberDto";
import { FamilyMembersDto } from "../store/models/family/FamilyMembersDto";
import { RewardsDto } from "../store/models/rewards/RewardsDto";
import { AppLinkDto } from "../store/models/appcontent/AppLinkDto";
import { AppSplashDto } from "../store/models/appcontent/AppSplashDto";
import { CarouselDto } from "../store/models/appcontent/CarouselDto";
import { CarouselInboundDto } from "../store/models/appcontent/CarouselInboundDto";
import { PassMediumDto } from "../store/models/passmedium/PassMediumDto";
import { CollectionIntervalDto } from "../store/models/customer/CollectionIntervalDto";

const REACT_APP_SERVER_URL = window.env.REACT_APP_SERVER_URL || process.env.REACT_APP_SERVER_URL;

const customerCareUrl =
  window.env.REACT_APP_CUSTOMER_CARE_URL ||
  process.env.REACT_APP_CUSTOMER_CARE_URL ||
  `${REACT_APP_SERVER_URL}/customercare/v1`;
const preferenceManagementUrl =
  window.env.REACT_APP_PREFERENCE_MANAGEMENT_URL ||
  process.env.REACT_APP_PREFERENCE_MANAGEMENT_URL ||
  `${REACT_APP_SERVER_URL}/preferencemanagement/v1`;
const bonusManagementUrl =
  window.env.REACT_APP_BONUS_MANAGEMENT_URL ||
  process.env.REACT_APP_BONUS_MANAGEMENT_URL ||
  `${REACT_APP_SERVER_URL}/bonusmanagement/v1`;
const customerInteractionUrl =
  window.env.REACT_APP_CUSTOMER_INTERACTION_URL ||
  process.env.REACT_APP_CUSTOMER_INTERACTION_URL ||
  `${REACT_APP_SERVER_URL}/customerinteraction/v1`;
const couponManagementUrl =
  window.env.REACT_APP_COUPON_MANAGEMENT_URL ||
  process.env.REACT_APP_COUPON_MANAGEMENT_URL ||
  `${REACT_APP_SERVER_URL}/couponmanagement/v1`;
const registrationUrl =
  window.env.REACT_APP_REGISTRATION_URL || process.env.REACT_APP_REGISTRATION_URL || `${REACT_APP_SERVER_URL}/auth/v1`;
const adminUserManagementUrl =
  window.env.REACT_APP_ADMIN_USER_MANAGEMENT_URL ||
  process.env.REACT_APP_ADMIN_USER_MANAGEMENT_URL ||
  `${REACT_APP_SERVER_URL}/adminusermanagement/v1`;
const appContentUrl =
  window.env.REACT_APP_ADMIN_APP_CONTENT_URL ||
  process.env.REACT_APP_ADMIN_APP_CONTENT_URL ||
  `${REACT_APP_SERVER_URL}/appcontent/v1`;
const historyUrl =
  window.env.REACT_APP_HISTORY_URL || process.env.REACT_APP_HISTORY_URL || `${REACT_APP_SERVER_URL}/history/v1`;
const dashboardUrl =
  window.env.REACT_APP_DASHBOARD_URL || process.env.REACT_APP_DASHBOARD_URL || `${REACT_APP_SERVER_URL}/dashboard/v1`;
const identityManagementUrl =
  window.env.REACT_APP_IDENTITY_MANAGEMENT_URL ||
  process.env.REACT_APP_IDENTITY_MANAGEMENT_URL ||
  `${REACT_APP_SERVER_URL}/identitymanagement/v1`;
const flowEngineUrl =
  window.env.REACT_APP_FLOW_ENGINE_URL ||
  process.env.REACT_APP_FLOW_ENGINE_URL ||
  `${REACT_APP_SERVER_URL}/flowengine/v1`;
const loyaltyCardEngelhornUrl =
  window.env.REACT_APP_LOYALTY_CARD_ENGELHORN ||
  process.env.REACT_APP_LOYALTY_CARD_ENGELHORN ||
  `${REACT_APP_SERVER_URL}/loyalty-card-engelhorn/v1`;

export const Api = {
  getFamilyByCustomerNumber: async (customerNumber: string): Promise<FamilyDto> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(
      `${customerCareUrl}/admin/families${objectToQueryString({
        customerNumber,
      })}`,
      requestOptions
    ).then(handleResponse);
  },
  deleteFamilyMembership: async (familyNumber: string, customerNumber: string): Promise<void> => {
    const requestOptions = await tokenRequestOptions("DELETE");
    return fetchWithTenant(`${customerCareUrl}/admin/families/${familyNumber}/membership`, {
      ...requestOptions,
      body: JSON.stringify({
        customerNumber,
      }),
    }).then(handleResponse);
  },
  updateFamilyMemberRoles: async (familyNumber: string, familyRoles: FamilyMembersDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("PUT");
    return fetchWithTenant(`${customerCareUrl}/admin/families/${familyNumber}/member-roles`, {
      ...requestOptions,
      body: JSON.stringify(familyRoles),
    }).then(handleResponse);
  },
  inviteFamilyMember: async (invitation: InvitationDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("POST");
    return fetchWithTenant(`${registrationUrl}/admin/invite`, {
      ...requestOptions,
      body: JSON.stringify(invitation),
    }).then(handleResponse);
  },
  transferFamilyMember: async (familyNumber: string, customerNumber: string): Promise<void> => {
    const requestOptions = await tokenRequestOptions("POST");
    const family: FamilyMembershipDto = {
      customerNumber: customerNumber,
      roleName: FamilyMembershipRole.MEMBER,
    };
    return fetchWithTenant(`${customerCareUrl}/admin/families/${familyNumber}/memberships`, {
      ...requestOptions,
      body: JSON.stringify(family),
    }).then(handleResponse);
  },
  getCustomerHistory: async (customerNumber: string): Promise<CustomerHistoryDto> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${historyUrl}/history${objectToQueryString({ customerNumber })}`, requestOptions).then(
      handleResponse
    );
  },
  getQuestions: async (): Promise<PreferenceDto> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${preferenceManagementUrl}/preferences/default`, requestOptions).then(handleResponse);
  },
  updateQuestion: async (question: QuestionDto): Promise<QuestionDto> => {
    const requestOptions = await tokenRequestOptions("PUT");
    return fetchWithTenant(`${preferenceManagementUrl}/preferences/default/questions/${question.questionNumber}`, {
      ...requestOptions,
      body: JSON.stringify(question),
    }).then(handleResponse);
  },
  createQuestion: async (question: QuestionDto): Promise<QuestionDto> => {
    const requestOptions = await tokenRequestOptions("POST");
    return fetchWithTenant(`${preferenceManagementUrl}/preferences/default/questions`, {
      ...requestOptions,
      body: JSON.stringify(question),
    }).then(handleResponse);
  },
  deleteQuestion: async (question: QuestionDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("DELETE");
    return fetchWithTenant(`${preferenceManagementUrl}/preferences/default/questions/${question.questionNumber}`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  getCustomerPreferences: async (customerNumber: string): Promise<PreferenceDto> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${customerCareUrl}/admin/customers/${customerNumber}/preferences`, requestOptions).then(
      handleResponse
    );
  },
  updateCustomerPreferences: async (customerPreferences: CustomerPreferenceDto[], customerNumber: string) => {
    const requestOptions = await tokenRequestOptions("PUT");
    return fetchWithTenant(`${customerCareUrl}/admin/customers/${customerNumber}/preferences`, {
      ...requestOptions,
      body: JSON.stringify(customerPreferences),
    }).then(handleResponse);
  },
  getAllBonusRules: async (): Promise<BonusRuleDto[]> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${bonusManagementUrl}/rules`, requestOptions).then(handleResponse);
  },
  createBonusRule: async (bonusRule: BonusRuleDto): Promise<BonusRuleDto> => {
    const requestOptions = await tokenRequestOptions("POST");
    return fetchWithTenant(`${bonusManagementUrl}/rules`, {
      ...requestOptions,
      body: JSON.stringify(bonusRule),
    }).then(handleResponse);
  },
  createBonusRuleWithFile: async (formData: FormData): Promise<BonusRuleDto> => {
    const requestOptions = await tokenRequestOptionsForMultipart("POST");
    return fetchWithTenant(`${bonusManagementUrl}/rules/upload`, {
      ...requestOptions,
      body: formData,
    }).then(handleResponse);
  },
  updateBonusRuleStatus: async (ruleNumber: string, status: BonusRuleStatus): Promise<BonusRuleDto> => {
    const requestOptions = await tokenRequestOptions("PUT");
    return fetchWithTenant(`${bonusManagementUrl}/rules/${ruleNumber}/states/current`, {
      ...requestOptions,
      body: JSON.stringify({ status }),
    }).then(handleResponse);
  },
  deleteBonusRule: async (bonusRule: BonusRuleDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("DELETE");
    return fetchWithTenant(`${bonusManagementUrl}/rules/${bonusRule.ruleNumber}`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  updateBonusRule: async (bonusRule: BonusRuleDto): Promise<BonusRuleDto> => {
    const requestOptions = await tokenRequestOptions("PUT");
    return fetchWithTenant(`${bonusManagementUrl}/rules/${bonusRule.ruleNumber}`, {
      ...requestOptions,
      body: JSON.stringify(bonusRule),
    }).then(handleResponse);
  },
  addBonusRuleFilter: async (formData: FormData, ruleNumber: string): Promise<FilterDto> => {
    const requestOptions = await tokenRequestOptionsForMultipart("POST");
    return fetchWithTenant(`${bonusManagementUrl}/rules/${ruleNumber}/filter`, {
      ...requestOptions,
      body: formData,
    }).then(handleResponse);
  },
  updateBonusRuleFilter: async (formData: FormData, ruleFilterNumber: string): Promise<FilterDto> => {
    const requestOptions = await tokenRequestOptionsForMultipart("PUT");
    return fetchWithTenant(`${bonusManagementUrl}/rules/filter/${ruleFilterNumber}`, {
      ...requestOptions,
      body: formData,
    }).then(handleResponse);
  },
  deleteBonusRuleFilter: async (ruleFilterNumber: string): Promise<void> => {
    const requestOptions = await tokenRequestOptionsForMultipart("DELETE");
    return fetchWithTenant(`${bonusManagementUrl}/rules/filter/${ruleFilterNumber}`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  findPropertyGroupValues: async (propertyNumber: string): Promise<FilterDto> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${bonusManagementUrl}/propertygroups/${propertyNumber}/values`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  findAllBonusPremiums: async (): Promise<BonusPremiumDto[]> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${couponManagementUrl}/premiums`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  deleteBonusPremium: async (bonusPremium: BonusPremiumDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("DELETE");
    return fetchWithTenant(`${couponManagementUrl}/premiums/${bonusPremium.premiumNumber}`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  createBonusPremium: async (bonusPremium: BonusPremiumDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("POST");
    return fetchWithTenant(`${couponManagementUrl}/premiums`, {
      ...requestOptions,
      body: JSON.stringify(bonusPremium),
    }).then(handleResponse);
  },
  updateBonusPremium: async (bonusPremium: BonusPremiumDto): Promise<BonusPremiumDto> => {
    const requestOptions = await tokenRequestOptions("PUT");
    return fetchWithTenant(`${couponManagementUrl}/premiums/${bonusPremium.premiumNumber}`, {
      ...requestOptions,
      body: JSON.stringify(bonusPremium),
    }).then(handleResponse);
  },
  updateBonusPremiumStatus: async (
    premiumNumber: string,
    premiumStatus: BonusPremiumStatus
  ): Promise<BonusPremiumDto> => {
    const requestOptions = await tokenRequestOptions("PUT");
    return fetchWithTenant(`${couponManagementUrl}/premiums/${premiumNumber}/states/current`, {
      ...requestOptions,
      body: JSON.stringify({ premiumStatus }),
    }).then(handleResponse);
  },
  getExternalCustomerId: async (externalIdentificationNumber: string): Promise<CustomerNumberDto> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${identityManagementUrl}/admin/customer/${externalIdentificationNumber}`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  customerSearch: async (customerSearch: CustomerSearchDto): Promise<CustomerDto[]> => {
    const requestOptions = await tokenRequestOptions("GET");
    const queryString = objectToQueryString(customerSearch);
    return fetchWithTenant(`${customerCareUrl}/admin/customers/customersearch${queryString}`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  findCustomer: async (customerNumber: string): Promise<CustomerDto> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${customerCareUrl}/admin/customers/${customerNumber}`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  updateCustomer: async (customer: CustomerDto): Promise<CustomerDto> => {
    const requestOptions = await tokenRequestOptions("PUT");
    return fetchWithTenant(`${customerCareUrl}/admin/customers/${customer.customerNumber}`, {
      ...requestOptions,
      body: JSON.stringify(customer),
    }).then(handleResponse);
  },

  updateCustomerStatus: async (customerNumber: string, customerStatus: CustomerStatusDto): Promise<CustomerDto> => {
    const requestOptions = await tokenRequestOptions("PUT");
    return fetchWithTenant(`${customerCareUrl}/admin/customers/${customerNumber}/states/current`, {
      ...requestOptions,
      body: JSON.stringify(customerStatus),
    }).then(handleResponse);
  },
  createAdjustmentEarn: async (adjustment: AdjustmentDto): Promise<AdjustmentDto> => {
    const requestOptions = await tokenRequestOptions("POST");
    return fetchWithTenant(
      `${customerInteractionUrl}/admin/adjustmentinteractions/earn/customers/${adjustment.customerNumber}`,
      {
        ...requestOptions,
        body: JSON.stringify(adjustment),
      }
    ).then(handleResponse);
  },
  createAdjustmentBurn: async (adjustment: AdjustmentDto): Promise<AdjustmentDto> => {
    const requestOptions = await tokenRequestOptions("POST");
    return fetchWithTenant(
      `${customerInteractionUrl}/admin/adjustmentinteractions/burn/customers/${adjustment.customerNumber}`,
      {
        ...requestOptions,
        body: JSON.stringify(adjustment),
      }
    ).then(handleResponse);
  },
  findCoupons: async (customerNumber: string): Promise<CouponDto[]> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${customerInteractionUrl}/customers/${customerNumber}/coupons`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  getRewards: async (customerNumber: string): Promise<RewardsDto> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${customerInteractionUrl}/customers/${customerNumber}/rewards`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  getCustomerCollectionInterval: async (customerNumber: string): Promise<CollectionIntervalDto> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(
      `${flowEngineUrl}/admin/customer/circle/upgrade${objectToQueryString({
        customerNumber,
      })}`,
      requestOptions
    ).then(handleResponse);
  },

  findAllCampaigns: async (): Promise<CampaignDto[]> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${couponManagementUrl}/campaigns`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  createCampaign: async (campaign: CampaignDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("POST");
    return fetchWithTenant(`${couponManagementUrl}/campaigns`, {
      ...requestOptions,
      body: JSON.stringify(campaign),
    }).then(handleResponse);
  },
  updateCampaign: async (campaign: CampaignDto) => {
    const requestOptions = await tokenRequestOptions("PUT");
    return fetchWithTenant(`${couponManagementUrl}/campaigns/${campaign.campaignNumber}`, {
      ...requestOptions,
      body: JSON.stringify(campaign),
    }).then(handleResponse);
  },
  getCustomerCountForCampaign: async (
    selectionCriteria: SelectionCriteriaDto
  ): Promise<CustomerCountForCampaignDto> => {
    const requestOptions = await tokenRequestOptions("GET");
    const url = `${customerInteractionUrl}/customers/count${objectToQueryString(selectionCriteria)}`;
    return fetchWithTenant(url, {
      ...requestOptions,
    }).then(handleResponse);
  },
  updateCampaignStatus: async (campaignNumber: string, campaignStatus: CampaignStatus) => {
    const requestOptions = await tokenRequestOptions("PUT");
    return fetchWithTenant(`${couponManagementUrl}/campaigns/${campaignNumber}/states/current`, {
      ...requestOptions,
      body: JSON.stringify({ campaignStatus }),
    }).then(handleResponse);
  },
  getOperationRootUnitList: async (): Promise<OperationalUnitDto[]> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${bonusManagementUrl}/units/rootlist`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  getDefaultOperationalUnitTypes: async (): Promise<OperationalUnitType[]> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${bonusManagementUrl}/unittypes`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  updateOperationalUnit: async (operationalUnit: OperationalUnitDto): Promise<OperationalUnitDto> => {
    const requestOptions = await tokenRequestOptions("PUT");
    return fetchWithTenant(`${bonusManagementUrl}/units/${operationalUnit.unitNumber}`, {
      ...requestOptions,
      body: JSON.stringify(operationalUnit),
    }).then(handleResponse);
  },
  updateOperationalUnitStatus: async (operationalUnit: OperationalUnitDto): Promise<OperationalUnitDto> => {
    const requestOptions = await tokenRequestOptions("PUT");
    return fetchWithTenant(`${bonusManagementUrl}/units/${operationalUnit.unitNumber}/states/current`, {
      ...requestOptions,
      body: JSON.stringify({ status: operationalUnit.unitStatus }),
    }).then(handleResponse);
  },
  deleteOperationalUnit: async (operationalUnit: OperationalUnitDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("DELETE");
    return fetchWithTenant(`${bonusManagementUrl}/units/${operationalUnit.unitNumber}`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  createOperationalUnit: async (operationalUnit: OperationalUnitDto): Promise<OperationalUnitDto> => {
    const requestOptions = await tokenRequestOptions("POST");
    return fetchWithTenant(`${bonusManagementUrl}/units`, {
      ...requestOptions,
      body: JSON.stringify(operationalUnit),
    }).then(handleResponse);
  },
  findAllUsers: async (): Promise<UserDto[]> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${adminUserManagementUrl}/users`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  deleteUser: async (user: UserDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("DELETE");
    return fetchWithTenant(`${adminUserManagementUrl}/users/${user.userNumber}`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  createAppLink: async (appLink: AppLinkDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("POST");
    return fetchWithTenant(`${appContentUrl}/admin/applink`, {
      ...requestOptions,
      body: JSON.stringify(appLink),
    }).then(handleResponse);
  },

  updateAppLink: async (appLink: AppLinkDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("PUT");
    return fetchWithTenant(`${appContentUrl}/admin/applink/${appLink.appLinkNumber}`, {
      ...requestOptions,
      body: JSON.stringify(appLink),
    }).then(handleResponse);
  },

  deleteAppLink: async (appLink: AppLinkDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("DELETE");
    return fetchWithTenant(`${appContentUrl}/admin/applink/${appLink.appLinkNumber}`, {
      ...requestOptions,
    }).then(handleResponse);
  },

  changeAppLink: async (appLinksToChange: AppLinkDto[]): Promise<AppLinkDto[]> => {
    const requestOptions = await tokenRequestOptions("PATCH");
    return fetchWithTenant(`${appContentUrl}/admin/applink/order/change`, {
      ...requestOptions,
      body: JSON.stringify(appLinksToChange),
    }).then(handleResponse);
  },

  getAllAppLinks: async (): Promise<AppLinkDto[]> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${appContentUrl}/admin/applink`, {
      ...requestOptions,
    }).then(handleResponse);
  },

  getSplashImage: async (): Promise<AppSplashDto> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${appContentUrl}/admin/splashscreen`, {
      ...requestOptions,
    }).then(handleResponse);
  },

  uploadSplashImage: async (formData: FormData): Promise<AppSplashDto> => {
    const requestOptions = await tokenRequestOptionsForMultipart("POST");
    return fetchWithTenant(`${appContentUrl}/admin/splashscreen`, {
      ...requestOptions,
      body: formData,
    }).then(handleResponse);
  },
  deleteSplashImage: async (): Promise<void> => {
    const requestOptions = await tokenRequestOptions("DELETE");
    return fetchWithTenant(`${appContentUrl}/admin/splashscreen`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  getAllCarousels: async (): Promise<CarouselDto[]> => {
    const requestOptions = await tokenRequestOptionsForMultipart("GET");
    return fetchWithTenant(`${appContentUrl}/admin/carousel`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  deleteCarousel: async (carousel: CarouselDto): Promise<void> => {
    const requestOptions = await tokenRequestOptionsForMultipart("DELETE");
    return fetchWithTenant(`${appContentUrl}/admin/carousel/${carousel.carouselNumber}`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  createCarousel: async (carousel: CarouselInboundDto, imageFile: File): Promise<void> => {
    const formData = new FormData();
    formData.append("data", JSON.stringify(carousel));
    formData.append("file", imageFile);
    const requestOptions = await tokenRequestOptionsForMultipart("POST"); // Add true to indicate FormData
    return fetchWithTenant(`${appContentUrl}/admin/carousel`, {
      ...requestOptions,
      body: formData,
    }).then(handleResponse);
  },
  createPassMedium: async (passMedium: PassMediumDto): Promise<void> => {
    const formData = new FormData();
    if (passMedium.stripImageFile) {
      formData.append("stripFile", passMedium.stripImageFile);
      formData.append("stripFileName", passMedium.stripImageFile.name);
    }
    if (passMedium.logoImageFile) {
      formData.append("logoFile", passMedium.logoImageFile);
      formData.append("logoFileName", passMedium.logoImageFile.name);
    }
    if (passMedium.iconImageFile) {
      formData.append("iconFile", passMedium.iconImageFile);
      formData.append("iconFileName", passMedium.iconImageFile.name);
    }
    formData.append("type", passMedium.type);
    formData.append("motiv", passMedium.motiv);
    if (passMedium.backgroundColor) {
      formData.append("backgroundColor", passMedium.backgroundColor);
    }

    if (passMedium.foregroundColor) {
      formData.append("foregroundColor", passMedium.foregroundColor);
    }
    if (passMedium.labelColor) {
      formData.append("labelColor", passMedium.labelColor);
    }
    if (passMedium.programName) {
      formData.append("programName", passMedium.programName);
    }
    if (passMedium.logoText) {
      formData.append("logoText", passMedium.logoText);
    }
    if (passMedium.includeName !== undefined) {
      formData.append("includeName", passMedium.includeName.toString());
    }
    if (passMedium.includeExternalConnectionId !== undefined) {
      formData.append("includeExternalConnectionId", passMedium.includeExternalConnectionId.toString());
    }
    if (passMedium.includePassId !== undefined) {
      formData.append("includePassId", passMedium.includePassId.toString());
    }
    if (passMedium.barcodeFormat) {
      formData.append("barcodeFormat", passMedium.barcodeFormat);
    }
    const requestOptions = await tokenRequestOptionsForMultipart("POST");
    return fetchWithTenant(`${identityManagementUrl}/passmedium`, {
      ...requestOptions,
      body: formData,
    }).then(handleResponse);
  },
  updateCarousel: async (carousel: CarouselInboundDto, imageFile: File, carouselNumber: string): Promise<void> => {
    const formData = new FormData();
    formData.append("data", JSON.stringify(carousel));
    formData.append("file", imageFile);
    const requestOptions = await tokenRequestOptionsForMultipart("PUT");
    return fetchWithTenant(`${appContentUrl}/admin/carousel/${carouselNumber}`, {
      ...requestOptions,
      body: formData,
    }).then(handleResponse);
  },
  getCarouselByNumber: async (carouselNumber: string): Promise<CarouselDto> => {
    const requestOptions = await tokenRequestOptionsForMultipart("GET");
    return fetchWithTenant(`${appContentUrl}/admin/carousel/${carouselNumber}`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  getPassMediumByNumber: async (passMediumNumber: string): Promise<PassMediumDto> => {
    const requestOptions = await tokenRequestOptionsForMultipart("GET");
    return fetchWithTenant(`${identityManagementUrl}/passmedium/${passMediumNumber}`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  changeCarousel: async (carouselToChange: CarouselDto[]): Promise<CarouselDto[]> => {
    const requestOptions = await tokenRequestOptions("PATCH");
    return fetchWithTenant(`${appContentUrl}/admin/carousel/order/change`, {
      ...requestOptions,
      body: JSON.stringify(carouselToChange),
    }).then(handleResponse);
  },
  createUser: async (user: UserDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("POST");
    return fetchWithTenant(`${adminUserManagementUrl}/users`, {
      ...requestOptions,
      body: JSON.stringify(user),
    }).then(handleResponse);
  },
  updateUser: async (user: UserDto): Promise<UserDto> => {
    const requestOptions = await tokenRequestOptions("PUT");
    return fetchWithTenant(`${adminUserManagementUrl}/users/${user.userNumber}`, {
      ...requestOptions,
      body: JSON.stringify(user),
    }).then(handleResponse);
  },
  changePassword: async (changePassword: ChangePasswordDto): Promise<void> => {
    delete changePassword.confirmNewPassword;
    const requestOptions = await tokenRequestOptions("PUT");
    return fetchWithTenant(`${adminUserManagementUrl}/password`, {
      ...requestOptions,
      body: JSON.stringify(changePassword),
    }).then(handleResponse);
  },

  getCashback: async (): Promise<CashbackDto> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${customerInteractionUrl}/admin/cashback`, requestOptions).then(handleResponse);
  },
  updateCashback: async (cashbackDto: CashbackDto): Promise<CashbackDto> => {
    const requestOptions = await tokenRequestOptions("PUT");
    return fetchWithTenant(`${customerInteractionUrl}/admin/cashback`, {
      ...requestOptions,
      body: JSON.stringify(cashbackDto),
    }).then(handleResponse);
  },

  getBonusXpress: async (): Promise<BonusXpressDto> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${bonusManagementUrl}/bonusxpress`, requestOptions).then(handleResponse);
  },
  updateBonusXpress: async (bonusXpress: BonusXpressDto): Promise<BonusXpressDto> => {
    const requestOptions = await tokenRequestOptions("PUT");
    return fetchWithTenant(`${bonusManagementUrl}/bonusxpress`, {
      ...requestOptions,
      body: JSON.stringify(bonusXpress),
    }).then(handleResponse);
  },
  register: async (registration: RegistrationDto): Promise<RegistrationCustomerDto> => {
    const requestOptions = await tokenRequestOptions("POST");
    return fetchWithTenant(`${registrationUrl}/admin/register`, {
      ...requestOptions,
      body: JSON.stringify(registration),
    }).then(handleResponse);
  },
  getBranchStoreList: async (): Promise<BranchStoreDto[]> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${customerInteractionUrl}/units/store`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  getRegistrationConfig: async (configGroups: ConfigGroupKey[]): Promise<ConfigDto[]> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(
      `${registrationUrl}/configs${objectToQueryString({
        configGroups,
      })}`,
      {
        ...requestOptions,
      }
    ).then(handleResponse);
  },
  getRegistrationFieldConfig: async (type: ConfigTypes): Promise<FieldConfigDto[]> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(
      `${registrationUrl}/configs/registration/fields${objectToQueryString({
        type,
      })}`,
      {
        ...requestOptions,
      }
    ).then(handleResponse);
  },
  getCustomerConfig: async (configGroups: ConfigGroupKey[]): Promise<ConfigDto[]> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(
      `${customerCareUrl}/configs${objectToQueryString({
        configGroups,
      })}`,
      {
        ...requestOptions,
      }
    ).then(handleResponse);
  },
  getCustomerFieldConfig: async (type: ConfigTypes): Promise<FieldConfigDto[]> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(
      `${customerCareUrl}/configs/customer/fields${objectToQueryString({
        type,
      })}`,
      {
        ...requestOptions,
      }
    ).then(handleResponse);
  },
  getDashboardKpis: async (date: string): Promise<InteractionKpiDayDto> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(
      `${dashboardUrl}/dashboard/interactions${objectToQueryString({
        date,
      })}`,
      {
        ...requestOptions,
      }
    ).then(handleResponse);
  },
  findReceiptDetailsForInteraction: async (interactionNumber: string): Promise<ReceiptDetailsDto> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${customerInteractionUrl}/interactions/${interactionNumber}/receiptDetails`, {
      ...requestOptions,
    }).then(handleResponse);
  },

  getBonusPointsInfo: async (customerNumber: string): Promise<CustomerBonusPointsDto> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${customerInteractionUrl}/customers/${customerNumber}/points`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  cancelCustomerMembership: async (membershipCancellation: MembershipCancellationDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("POST");
    return fetchWithTenant(
      `${customerCareUrl}/admin/customers/${membershipCancellation.customerNumber}/cancel-membership/`,
      {
        ...requestOptions,
        body: JSON.stringify(membershipCancellation),
      }
    ).then(handleResponse);
  },
  revokeCancelCustomerMembership: async (customerNumber?: string): Promise<void> => {
    const requestOptions = await tokenRequestOptions("DELETE");
    return fetchWithTenant(`${customerCareUrl}/admin/customers/${customerNumber}/revoke-cancel-membership/`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  newReplacementCard: async (replacementCard: ReplacementCardDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("PUT");
    return fetchWithTenant(`${loyaltyCardEngelhornUrl}/admin/cards/`, {
      ...requestOptions,
      body: JSON.stringify(replacementCard),
    }).then(handleResponse);
  },
  replaceIdentifications: async (
    customerNumber: string,
    replaceIdentificcationDto: ReplacementIdentifactionDto
  ): Promise<void> => {
    const requestOptions = await tokenRequestOptions("POST");
    return fetchWithTenant(`${identityManagementUrl}/admin/customers/${customerNumber}/replaceIdentifications`, {
      ...requestOptions,
      body: JSON.stringify(replaceIdentificcationDto),
    }).then(handleResponse);
  },
  createCircle: async (circle: CircleDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("POST");
    return fetchWithTenant(`${bonusManagementUrl}/circles`, {
      ...requestOptions,
      body: JSON.stringify(circle),
    }).then(handleResponse);
  },
  updateCircle: async (circle: CircleDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("PUT");
    return fetchWithTenant(`${bonusManagementUrl}/circles/${circle.circleNumber}`, {
      ...requestOptions,
      body: JSON.stringify(circle),
    }).then(handleResponse);
  },
  deleteCircle: async (circle: CircleDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("DELETE");
    return fetchWithTenant(`${bonusManagementUrl}/circles/${circle.circleNumber}`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  getAllCircles: async (): Promise<CircleDto[]> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${bonusManagementUrl}/circles`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  getEinGeneratorTemplates: async (): Promise<EinGeneratorTemplateDto[]> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${identityManagementUrl}/admin/eingeneratortemplates`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  createConfigValue: async (configGroupKey: ConfigGroupKey, configValue: ConfigValueDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("POST");
    return fetchWithTenant(`${bonusManagementUrl}/configgroups/${configGroupKey}/configvalues`, {
      ...requestOptions,
      body: JSON.stringify(configValue),
    }).then(handleResponse);
  },
  updateConfigValue: async (configGroupKey: ConfigGroupKey, configValue: ConfigValueDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("PUT");
    return fetchWithTenant(`${bonusManagementUrl}/configgroups/${configGroupKey}/configvalues/${configValue.key}`, {
      ...requestOptions,
      body: JSON.stringify(configValue),
    }).then(handleResponse);
  },
  deleteConfigValue: async (configGroupKey: ConfigGroupKey, configValue: ConfigValueDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("DELETE");
    return fetchWithTenant(`${bonusManagementUrl}/configgroups/${configGroupKey}/configvalues/${configValue.key}`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  getConfigGroupBy: async (configGroupKey: ConfigGroupKey): Promise<ConfigGroupDto> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${bonusManagementUrl}/configgroups/${configGroupKey}`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  createIdentificationType: async (identificationType: IdentificationTypeDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("POST");
    return fetchWithTenant(`${identityManagementUrl}/admin/identificationtypes`, {
      ...requestOptions,
      body: JSON.stringify(identificationType),
    }).then(handleResponse);
  },
  updateIdentificationType: async (identificationType: IdentificationTypeDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("PUT");
    return fetchWithTenant(
      `${identityManagementUrl}/admin/identificationtypes/${identificationType.identificationTypeNumber}`,
      {
        ...requestOptions,
        body: JSON.stringify(identificationType),
      }
    ).then(handleResponse);
  },
  deleteIdentificationType: async (identificationType: IdentificationTypeDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("DELETE");
    return fetchWithTenant(
      `${identityManagementUrl}/admin/identificationtypes/${identificationType.identificationTypeNumber}`,
      {
        ...requestOptions,
      }
    ).then(handleResponse);
  },
  getAllIdentificationTypes: async (): Promise<IdentificationTypeDto[]> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${identityManagementUrl}/admin/identificationtypes`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  getAllPassMediums: async (): Promise<PassMediumDto[]> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${identityManagementUrl}/passmedium`, {
      ...requestOptions,
    }).then(handleResponse);
  },

  updatePassMedium: async (passMedium: PassMediumDto, passMediumNumber: string): Promise<void> => {
    const formData = new FormData();
    if (passMedium.stripImageFile) {
      formData.append("stripFile", passMedium.stripImageFile);
      formData.append("stripFileName", passMedium.stripImageFile.name);
    }
    if (passMedium.logoImageFile) {
      formData.append("logoFile", passMedium.logoImageFile);
      formData.append("logoFileName", passMedium.logoImageFile.name);
    }
    if (passMedium.iconImageFile) {
      formData.append("iconFile", passMedium.iconImageFile);
      formData.append("iconFileName", passMedium.iconImageFile.name);
    }
    if (passMedium.backgroundColor) {
      formData.append("backgroundColor", passMedium.backgroundColor);
    }
    if (passMedium.foregroundColor) {
      formData.append("foregroundColor", passMedium.foregroundColor);
    }
    if (passMedium.labelColor) {
      formData.append("labelColor", passMedium.labelColor);
    }
    if (!passMedium.programName) {
      passMedium.programName = "";
    }
    formData.append("programName", passMedium.programName);

    if (!passMedium.logoText) {
      passMedium.logoText = "";
    }
    formData.append("logoText", passMedium.logoText);

    if (passMedium.includeName !== undefined) {
      formData.append("includeName", passMedium.includeName.toString());
    }
    if (passMedium.includeExternalConnectionId !== undefined) {
      formData.append("includeExternalConnectionId", passMedium.includeExternalConnectionId.toString());
    }
    if (passMedium.includePassId !== undefined) {
      formData.append("includePassId", passMedium.includePassId.toString());
    }
    if (passMedium.barcodeFormat) {
      formData.append("barcodeFormat", passMedium.barcodeFormat);
    }
    const requestOptions = await tokenRequestOptionsForMultipart("PUT");
    return fetchWithTenant(`${identityManagementUrl}/passmedium/${passMediumNumber}`, {
      ...requestOptions,
      body: formData,
    }).then(handleResponse);
  },

  deletePassMedium: async (passMedium: PassMediumDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("DELETE");
    return fetchWithTenant(`${identityManagementUrl}/passmedium/${passMedium.passMediumNumber}`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  getCustomerIdentifications: async (customerNumber: string): Promise<CustomerIdentificationDto[]> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${identityManagementUrl}/admin/customers/${customerNumber}/identifications`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  getCustomerCustomFields: async (customerNumber: string): Promise<CustomerIdentificationDto[]> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${customerCareUrl}/admin/customers/${customerNumber}/customfields`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  createCustomerCustomField: async (
    customerNumber: string,
    customField: CustomerCustomFieldDto
  ): Promise<CustomerIdentificationDto[]> => {
    const requestOptions = await tokenRequestOptions("POST");
    return fetchWithTenant(`${customerCareUrl}/admin/customers/${customerNumber}/customfields`, {
      ...requestOptions,
      body: JSON.stringify(customField),
    }).then(handleResponse);
  },
  updateCustomerCustomField: async (
    customerNumber: string,
    customField: CustomerCustomFieldDto
  ): Promise<CustomerIdentificationDto[]> => {
    const requestOptions = await tokenRequestOptions("PUT");
    return fetchWithTenant(
      `${customerCareUrl}/admin/customers/${customerNumber}/customfields/${customField.fieldName}`,
      {
        ...requestOptions,
        body: JSON.stringify({ fieldValue: customField.fieldValue }),
      }
    ).then(handleResponse);
  },
  deleteCustomerCustomField: async (
    customerNumber: string,
    customField: CustomerCustomFieldDto
  ): Promise<CustomerIdentificationDto[]> => {
    const requestOptions = await tokenRequestOptions("DELETE");
    return fetchWithTenant(
      `${customerCareUrl}/admin/customers/${customerNumber}/customfields/${customField.fieldName}`,
      {
        ...requestOptions,
      }
    ).then(handleResponse);
  },
  createFlow: async (flow: FlowGroupDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("POST");
    return fetchWithTenant(`${flowEngineUrl}/admin/flows`, {
      ...requestOptions,
      body: JSON.stringify(flow),
    }).then(handleResponse);
  },
  updateFlow: async (flow: FlowGroupDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("PUT");
    return fetchWithTenant(`${flowEngineUrl}/admin/flows/${flow.flowGroupNumber}`, {
      ...requestOptions,
      body: JSON.stringify(flow),
    }).then(handleResponse);
  },
  deleteFlow: async (flow: FlowGroupDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("DELETE");
    return fetchWithTenant(`${flowEngineUrl}/admin/flows/${flow.flowGroupNumber}`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  createFlowDetails: async (flow: FlowDetailsDto, flowGroupNumber: string): Promise<void> => {
    const requestOptions = await tokenRequestOptions("POST");
    return fetchWithTenant(`${flowEngineUrl}/admin/flows/${flowGroupNumber}/details`, {
      ...requestOptions,
      body: JSON.stringify(flow),
    }).then(handleResponse);
  },
  updateFlowDetails: async (flow: FlowDetailsDto, flowGroupNumber: string): Promise<void> => {
    const requestOptions = await tokenRequestOptions("PUT");
    return fetchWithTenant(`${flowEngineUrl}/admin/flows/${flowGroupNumber}/details/${flow.flowDetailNumber}`, {
      ...requestOptions,
      body: JSON.stringify(flow),
    }).then(handleResponse);
  },
  deleteFlowDetails: async (flow: FlowDetailsDto, flowGroupNumber: string): Promise<void> => {
    const requestOptions = await tokenRequestOptions("DELETE");
    return fetchWithTenant(`${flowEngineUrl}/admin/flows/${flowGroupNumber}/details/${flow.flowDetailNumber}`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  getAllFlows: async (): Promise<FlowGroupDto[]> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${flowEngineUrl}/admin/flows`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  interactionSearch: async (interactionSearch: InteractionSearchDto): Promise<InteractionSearchResponseDto[]> => {
    const requestOptions = await tokenRequestOptions("GET");
    const queryString = objectToQueryString(interactionSearch);
    return fetchWithTenant(`${customerInteractionUrl}/interactionsearch${queryString}`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  getGdprTemplates: async (): Promise<GdprTemplates> => {
    const requestOptions = await tokenRequestOptions("GET");
    return fetchWithTenant(`${customerCareUrl}/admin/templates/gdpr`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  updateGdprTemplates: async (gdprTemplate: GdprTemplates): Promise<void> => {
    const requestOptions = await tokenRequestOptions("PUT");
    return fetchWithTenant(`${customerCareUrl}/admin/templates/gdpr`, {
      ...requestOptions,
      body: JSON.stringify(gdprTemplate),
    }).then(handleResponse);
  },
  createGdprDocument: async (customerNumber: string, gdprRequestDto: GdprRequestDto): Promise<void> => {
    const requestOptions = await tokenRequestOptions("POST");
    return fetchWithTenant(`${customerCareUrl}/admin/templates/gdpr/${customerNumber}`, {
      ...requestOptions,
      body: JSON.stringify(gdprRequestDto),
    }).then((response) => handleFileResponse(response, "DSGVO-Auskunft.pdf"));
  },
  doubleOptIn: async (customerNumber: string, token: string, accessToken: string): Promise<ConfigDto[]> => {
    const requestOptions = await tokenRequestOptionsOneTime("PUT", accessToken);
    return fetchWithTenant(`${customerCareUrl}/customers/${customerNumber}/states/optin?token=${token}`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  doubleOptOut: async (customerNumber: string, token: string, accessToken: string): Promise<ConfigDto[]> => {
    const requestOptions = await tokenRequestOptionsOneTime("PUT", accessToken);
    return fetchWithTenant(`${customerCareUrl}/customers/${customerNumber}/states/optout?token=${token}`, {
      ...requestOptions,
    }).then(handleResponse);
  },
  downloadPkPass: async (passNumber: string, token: string, accessToken: string): Promise<Response> => {
    const requestOptions = await tokenRequestOptionsOneTime("GET", accessToken);
    return fetchWithTenant(`${identityManagementUrl}/passes/${passNumber}/pkpass?token=${token}`, {
      ...requestOptions,
    }).then((response) => handleFileResponse(response, "pkpass.pkpass"));
  },
  downloadPassForCustomerIdentificationNumber: async (
    customerIdentificationNumber: string,
    token: string,
    accessToken: string
  ): Promise<Response> => {
    const requestOptions = await tokenRequestOptionsOneTime("GET", accessToken);
    return fetchWithTenant(
      `${identityManagementUrl}/passes/customeridentification/${customerIdentificationNumber}/pkpass?token=${token}`,
      {
        ...requestOptions,
      }
    ).then((response) => handleFileResponse(response, "pkpass.pkpass"));
  },
};

export function objectToQueryString(obj: object) {
  const entries = Object.entries(obj).filter(([_, value]) => value !== undefined);
  return entries.length ? `?${entries.map(([key, value]) => key + "=" + value).join("&")}` : "";
}

async function handleResponse(response: Response) {
  const text = await response.text();
  let data;
  try {
    data = JSON.parse(text);
  } catch {
    if (response.status !== 200 && response.status !== 201 && response.status !== 204) throw response.statusText;
  }
  if (data?.error) {
    throw data.error;
  }
  return data;
}

async function handleFileResponse(response: Response, defaultFileName: string) {
  if (response.status === 200) {
    const fileName = response.headers.get("content-disposition")?.split("filename=")[1];

    response.blob().then((blob) => {
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = fileName ?? defaultFileName;
      link.click();
      link.parentNode?.removeChild(link);
    });
    return;
  }
  return handleResponse(response);
}
