import { useTranslation } from "react-i18next";

export enum QuestionType {
  SINGLE = "SINGLE",
  MULTIPLE = "MULTIPLE",
}

export const useQuestionTypeOptions = () => {
  const { t } = useTranslation();

  return [
    {
      label: t("preferenceQuestionCreate.labelOneAnswer"),
      value: QuestionType.SINGLE,
    },
    {
      label: t("preferenceQuestionCreate.labelMultipleAnswers"),
      value: QuestionType.MULTIPLE,
    },
  ];
};
