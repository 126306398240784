import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, useTheme } from "@mui/material";
import React from "react";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkUpdateCustomerStatus } from "../../store/actions/CustomerCareActions";
import { IStore } from "../../store/IStore";
import {
  CustomerStatus,
  CustomerStatusDto,
  customerStatusOptions,
} from "../../store/models/customer/CustomerStatusDto";
import { FormInput } from "../atoms/FormInput";
import { FormRadioButton } from "../atoms/FormRadioButton";
import { isBlank, ValidationErrors } from "../atoms/Utils";
import NotificationList from "../notification/NotificationList";
import { useTranslation } from "react-i18next";

interface CustomerStatusDialogProps {
  setCustomerStatusDialogVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomerStatusDialog = (props: CustomerStatusDialogProps & ThunkProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { setCustomerStatusDialogVisible, customer } = props;
  const updateCustomerStatus = async (customerStatus: CustomerStatusDto) => {
    customer &&
      customer.customerNumber &&
      (await props.thunkUpdateCustomerStatus(customer.customerNumber, customerStatus));
    setCustomerStatusDialogVisible(false);
  };

  if (!customer) return null;

  const validateForm = (values: CustomerStatusDto) => {
    const errors: ValidationErrors<CustomerStatusDto> = {};

    if (!values.status) {
      errors.status = t("messages.validation.required");
    }

    if (isBlank(values.note)) {
      errors.note = t("messages.validation.required");
    }
    return errors;
  };
  return (
    <Dialog onClose={() => setCustomerStatusDialogVisible(false)} open={true}>
      <NotificationList isDialog />
      <Form
        onSubmit={updateCustomerStatus}
        initialValues={{
          status: customer.status,
          note:
            customer.status === CustomerStatus.MIGRATED || customer.status === CustomerStatus.CONSENT_FAILED
              ? "Der Kunde wurde auf die Datenschutzbestimmungen und AGB hingewiesen und akzeptiert diese."
              : "",
        }}
        validate={validateForm}
        render={({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle>{t("customerStatusDialog.title")}</DialogTitle>
            <Divider variant="fullWidth" orientation="horizontal" />
            <DialogContent style={{ padding: theme.spacing(3) }}>
              <Grid item>
                <FormRadioButton
                  name="status"
                  options={customerStatusOptions(t).filter(
                    (option) => !option.disabled || option.value === values.status
                  )}
                  value={values.status}
                />
              </Grid>
              <Grid item>
                <FormInput type="textarea" name="note" label={t("customerStatusDialog.note")} />
              </Grid>
            </DialogContent>
            <Divider variant="fullWidth" orientation="horizontal" />
            <DialogActions style={{ padding: theme.spacing(3) }}>
              <Grid container justifyContent="flex-end">
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => setCustomerStatusDialogVisible(false)}
                  style={{ marginRight: theme.spacing(3) }}
                >
                  {t("common.cancel")}
                </Button>
                <Button color="primary" type="submit" variant="contained" disabled={submitting}>
                  {t("common.buttonDone")}
                </Button>
              </Grid>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

const mapStateToProps = (state: IStore) => ({
  customer: state.customerCare.customer,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkUpdateCustomerStatus,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(CustomerStatusDialog);
