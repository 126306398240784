import { Api } from "../../api/Api";
import { BooleanThunk } from "../IStore";
import { ReplacementCardDto } from "../models/identification/ReplacementCardDto";
import { reloadCustomerHistory } from "./HistoryActions";
import { thunkCreateErrorNotification, thunkCreateSuccessNotification } from "./NotificationActions";
import { ReplacementIdentifactionDto } from "../models/identification/ReplacementIdentifactionDto";

export const thunkReplaceIdentifications =
  (customerNumber: string, replaceIdentificcationDto: ReplacementIdentifactionDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.replaceIdentifications(customerNumber, replaceIdentificcationDto);
      reloadCustomerHistory(dispatch, customerNumber);
      dispatch(thunkCreateSuccessNotification("Ersatzkartenanfrage erfolgreich gesendet"));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to send replacement card request", e));
      return false;
    }
  };

export const thunkNewReplacementCard =
  (replacementCard: ReplacementCardDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.newReplacementCard(replacementCard);
      reloadCustomerHistory(dispatch, replacementCard.customerNumber);
      dispatch(thunkCreateSuccessNotification("Ersatzkartenanfrage erfolgreich gesendet"));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to send replacement card request", e));
      return false;
    }
  };