const TENANT_REGEX = window.env.REACT_APP_TENANT_REGEX_ADMIN || process.env.REACT_APP_TENANT_REGEX_ADMIN;

export const getTenant = () => {
  if (!TENANT_REGEX) {
    return "harbor";
  }
  const hostname = window.location.hostname;
  const pattern = TENANT_REGEX && new RegExp(TENANT_REGEX);
  const match = hostname.match(pattern);
  if (match && match[1]) {
    return match[1];
  }
  return "harbor";
};

export const isAllowedToSeeWalletIds = () => {
  // Regex
  const searchString = getTenant().toLowerCase();
  return !searchString.includes("engelhorn"); //TODO: temporary check, will be remove soon
};

export const replaceTenantPlaceholder = (url: string) => {
  return url.replaceAll("{tenant}", getTenant());
};

export const fetchWithTenant = (url: string, options?: RequestInit) => {
  const apiUrl = replaceTenantPlaceholder(url);
  return fetch(apiUrl, options);
};
