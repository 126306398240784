import { v4 as uuid } from "uuid";
import { Api } from "../../api/Api";
import { BooleanThunk } from "../IStore";
import { BonusRuleDto } from "../models/bonusRule/BonusRuleDto";
import { BonusRuleStatus } from "../models/bonusRule/BonusRuleStatus";
import { thunkCreateErrorNotification, thunkCreateSuccessNotification } from "./NotificationActions";
import i18n from "../../i18n";

export const GET_BONUS_RULES = "GET_BONUS_RULES";
export const ADD_BONUS_RULE = "ADD_BONUS_RULES";
export const DELETE_BONUS_RULE = "DELETE_BONUS_RULE";
export const ACTIVATE_BONUS_RULE = "ACTIVATE_BONUS_RULE";
export const DEACTIVATE_BONUS_RULE = "DEACTIVATE_BONUS_RULE";
export const UPDATE_BONUS_RULE = "UPDATE_BONUS_RULES";
export const ADD_BONUS_RULE_FILTER = "ADD_BONUS_RULE_FILTER";
export const UPDATE_BONUS_RULE_FILTER = "UPDATE_BONUS_RULE_FILTER";
export const DELETE_BONUS_RULE_FILTER = "DELETE_BONUS_RULE_FILTER";

export const thunkGetBonusRules = (): BooleanThunk => async (dispatch) => {
  try {
    const bonusRules = await Api.getAllBonusRules();
    dispatch({
      type: GET_BONUS_RULES,
      payload: bonusRules,
    });
    return true;
  } catch (e) {
    dispatch(thunkCreateErrorNotification("Failed to load bonus rules", e));
    return false;
  }
};

export const thunkAddBonusRule =
  (newRule: BonusRuleDto): BooleanThunk =>
  async (dispatch) => {
    try {
      newRule.ruleNumber = `NEW-RULE-${uuid()}`;
      const rule = await Api.createBonusRule(newRule);
      dispatch({
        type: ADD_BONUS_RULE,
        payload: rule,
      });
      dispatch(thunkCreateSuccessNotification(i18n.t("messages.error.bonusRuleActions.createSuccessfully")));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to create the bonus rule", e));
      return false;
    }
  };

export const thunkAddBonusRuleWithFile =
  (formData: FormData): BooleanThunk =>
  async (dispatch) => {
    try {
      const rule = await Api.createBonusRuleWithFile(formData);
      dispatch({
        type: ADD_BONUS_RULE,
        payload: rule,
      });
      dispatch(thunkCreateSuccessNotification(i18n.t("messages.error.bonusRuleActions.createSuccessfully")));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to create the bonus rule filter", e));
      return false;
    }
  };

export const thunkUpdateBonusRule =
  (bonusRule: BonusRuleDto): BooleanThunk =>
  async (dispatch) => {
    try {
      const updatedBonusRule = await Api.updateBonusRule(bonusRule);
      dispatch({
        type: UPDATE_BONUS_RULE,
        payload: updatedBonusRule,
      });
      dispatch(thunkCreateSuccessNotification(i18n.t("messages.error.bonusRuleActions.updateSuccessfully")));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to update the bonus rule", e));
      return false;
    }
  };

export const thunkDeleteBonusRule =
  (bonusRule: BonusRuleDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.deleteBonusRule(bonusRule);
      dispatch({
        type: DELETE_BONUS_RULE,
        payload: bonusRule,
      });
      dispatch(thunkCreateSuccessNotification(i18n.t("messages.error.bonusRuleActions.deleteSuccessfully")));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to delete the bonus rule", e));
      return false;
    }
  };

export const thunkAddBonusRuleFilter =
  (formData: FormData, ruleNumber: string): BooleanThunk =>
  async (dispatch) => {
    try {
      const ruleFilter = await Api.addBonusRuleFilter(formData, ruleNumber);
      dispatch({
        type: ADD_BONUS_RULE_FILTER,
        payload: ruleFilter,
        ruleNumber,
      });
      dispatch(thunkCreateSuccessNotification(i18n.t("messages.error.bonusRuleActions.createFilterSuccessfully")));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to create the bonus rule filter", e));
      return false;
    }
  };

export const thunkUpdateBonusRuleFilter =
  (formData: FormData, filterNumber: string): BooleanThunk =>
  async (dispatch) => {
    try {
      const ruleFilter = await Api.updateBonusRuleFilter(formData, filterNumber);
      dispatch({
        type: UPDATE_BONUS_RULE_FILTER,
        payload: ruleFilter,
        filterNumber,
      });
      dispatch(thunkCreateSuccessNotification(i18n.t("messages.error.bonusRuleActions.createFilterSuccessfully")));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to create the bonus rule filter", e));
      return false;
    }
  };

export const thunkDeleteBonusRuleFilter =
  (filterNumber: string): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.deleteBonusRuleFilter(filterNumber);
      dispatch({
        type: DELETE_BONUS_RULE_FILTER,
        payload: filterNumber,
      });
      dispatch(thunkCreateSuccessNotification(i18n.t("messages.error.bonusRuleActions.deleteFilterSuccessfully")));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to delete the bonus rule filter", e));
      return false;
    }
  };

export const thunkActivateBonusRule =
  (bonusRule: BonusRuleDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.updateBonusRuleStatus(bonusRule.ruleNumber, BonusRuleStatus.ENABLED);
      dispatch({
        type: ACTIVATE_BONUS_RULE,
        payload: bonusRule,
      });
      dispatch(thunkCreateSuccessNotification(i18n.t("messages.error.bonusRuleActions.activateSuccessfully")));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to activate the bonus rule", e));
      return false;
    }
  };

export const thunkDeactivateBonusRule =
  (bonusRule: BonusRuleDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.updateBonusRuleStatus(bonusRule.ruleNumber, BonusRuleStatus.DISABLED);
      dispatch({
        type: DEACTIVATE_BONUS_RULE,
        payload: bonusRule,
      });
      dispatch(thunkCreateSuccessNotification(i18n.t("messages.error.bonusRuleActions.deactivateSuccessfully")));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to deactivate the bonus rule", e));
      return false;
    }
  };
