import { Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../store/IStore";
import { CustomerStatusDto } from "../../store/models/dashboard/CustomerStatusDto";
import { COLOR_BLACK, COLOR_GRAY_2 } from "../atoms/ImsMaterialTheme";
import { formatNumber } from "../atoms/Utils";
import { useTranslation } from "react-i18next";

export interface CustomerStatusCountProps {
  field: keyof CustomerStatusDto;
  status: string;
  icon: React.ReactElement;
  color: string;
}

const CustomerStatusCount = (props: ThunkProps & CustomerStatusCountProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { customerStatuses, field, status, icon, color } = props;

  const statusToday =
    !customerStatuses || customerStatuses.offset || !customerStatuses.data.length
      ? undefined
      : customerStatuses.data[customerStatuses.data.length - 1][field];
  if (!statusToday) return null;
  return (
    <>
      <Grid
        item
        md
        style={{
          borderLeftWidth: "2px",
          borderLeftStyle: "solid",
          borderLeftColor: color,
          paddingLeft: theme.spacing(1),
          marginBottom: theme.spacing(1),
        }}
        container
        direction="column"
        justifyContent="space-between"
      >
        <Grid item md={12} style={{ display: "flex", alignItems: "center" }}>
          {icon}
          <Typography style={{ paddingLeft: theme.spacing(1) }}>
            {t("dashboard.statusLabel")} {status}
          </Typography>
        </Grid>
        <Grid item md={12} style={{ display: "flex", alignItems: "baseline" }}>
          <Typography
            style={{
              color: COLOR_BLACK,
              paddingLeft: theme.spacing(1),
              fontWeight: "bold",
              fontSize: "32px",
              paddingRight: theme.spacing(1),
            }}
          >
            {formatNumber(statusToday)}
          </Typography>
          <Typography variant="subtitle1" style={{ color: COLOR_GRAY_2, fontWeight: "bold" }}>
            {t("dashboard.members")}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state: IStore) => ({
  customerStatuses: state.dashboard.customerStatuses,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(CustomerStatusCount);
