import { useTranslation } from "react-i18next";

export enum Gender {
  DIVERSE = "DIVERSE",
  FEMALE = "FEMALE",
  MALE = "MALE",
}

export const useGenderOptions = () => {
  const { t } = useTranslation();
  return [
    { value: "", label: t("registration.inputPlaceHolder") },
    { label: t("registration.male"), value: Gender.MALE },
    { label: t("registration.female"), value: Gender.FEMALE },
    { label: t("registration.diverse"), value: Gender.DIVERSE },
  ];
};

export const useGenderText = (gender?: Gender) => {
  const option = useGenderOptions().find((option) => option.value === gender);
  return option ? option.label : "";
};
