import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, Typography, useTheme } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../store/IStore";
import { DateTag } from "../atoms/DateTag";
import ImsEmbeddedTable, { ImsHeadCell, ImsTableRow } from "../atoms/ImsEmbeddedTable";
import { COLOR_GRAY_1 } from "../atoms/ImsMaterialTheme";
import { formatEuro, getCurrentYear } from "../atoms/Utils";
import NotificationList from "../notification/NotificationList";
import { formatBonusPoints } from "./KeyFigures";
import { formatCustomerName, useFormatInteractionCol, PaymentModel } from "./PaymentModel";
import { useTranslation } from "react-i18next";

function getTotalPaymentAmount(payments: PaymentModel[], year?: number) {
  return formatEuro(
    payments
      .filter((payment) => !year || payment.year === year)
      .reduce((prev, cur) => prev + cur.displayedPaymentAmount, 0)
  );
}

interface PaymentDialogProps {
  closeDialog: () => void;
  payments: PaymentModel[];
}

const PaymentDialog = (props: PaymentDialogProps & ThunkProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { closeDialog, payments, family } = props;

  const formatInteractionCol = useFormatInteractionCol();

  const headCells: ImsHeadCell[] = [
    {
      align: "left",
      title: t("paymentDialog.headingDate"),
      cellStyle: { verticalAlign: "top" },
    },
    {
      align: "left",
      title: t("paymentDialog.headingInteraction"),
      cellStyle: { verticalAlign: "bottom" },
    },
    {
      align: "center",
      title: t("paymentDialog.headingMember"),
      cellStyle: { verticalAlign: "top" },
    },
    {
      align: "center",
      title: t("paymentDialog.headingAmount"),
      cellStyle: { verticalAlign: "bottom" },
    },
    {
      align: "center",
      title: t("paymentDialog.headingPoints"),
      cellStyle: { verticalAlign: "bottom" },
    },
  ];

  const rows: ImsTableRow[] = payments.map((row, index) => ({
    value: row,
    nodes: [
      <DateTag date={row.interactionTimestamp} />,
      formatInteractionCol(row),
      formatCustomerName(row.customerNumber, family),
      <>{formatEuro(row.displayedPaymentAmount)}</>,
      formatBonusPoints(row.bonusPoints),
    ],
    key: index,
  }));

  return (
    <Dialog onClose={closeDialog} open={true} fullWidth={true} maxWidth="lg">
      <NotificationList isDialog />
      <DialogTitle style={{ padding: theme.spacing(2, 3, 1, 3) }}>
        <Grid container>
          <Grid
            item
            md={6}
            style={{
              alignSelf: "center",
            }}
          >
            <b>{t("paymentDialog.title")}</b>
          </Grid>
          <Grid item md={6}>
            <Grid container style={{ textAlign: "end" }}>
              <Grid item md={6}>
                <Typography variant="body1" align="right">
                  {t("paymentDialog.labelTotal")}
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant="h2" align="right">
                  {getTotalPaymentAmount(payments)}
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant="caption" align="right">
                  {t("paymentDialog.labelThisYear")}
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant="caption" align="right">
                  {getTotalPaymentAmount(payments, getCurrentYear())}
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant="caption" align="right">
                  {getCurrentYear() - 1}
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant="caption" align="right">
                  {getTotalPaymentAmount(payments, getCurrentYear() - 1)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider style={{ color: COLOR_GRAY_1, height: "2px" }} light={true} />
      <DialogContent
        style={{
          padding: theme.spacing(0),
          overflow: "hidden",
          display: "flex",
        }}
      >
        {payments?.length ? (
          <ImsEmbeddedTable headCells={headCells} rows={rows} />
        ) : (
          <p>{t("paymentDialog.noDataFound")}</p>
        )}
      </DialogContent>
      <Divider style={{ color: COLOR_GRAY_1, height: "2px" }} light={true} />
      <Grid
        container
        justifyContent="flex-end"
        style={{
          padding: theme.spacing(2),
        }}
      >
        <Button
          id="btndone"
          color="primary"
          type="button"
          variant="contained"
          style={{ cursor: "pointer" }}
          onClick={closeDialog}
        >
          {t("common.buttonDone")}
        </Button>
      </Grid>
    </Dialog>
  );
};

const mapStateToProps = (state: IStore) => ({
  family: state.customerCare.family,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(PaymentDialog);
