import { ThemeProvider } from "@mui/material";
import { configureStore } from "@reduxjs/toolkit";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./components/app/App";
import keycloak from "./keycloak";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { ImsMaterialTheme } from "./components/atoms/ImsMaterialTheme";
import reducer from "./store/reducers/RootReducer";
import "./i18n";

declare global {
  namespace NodeJS {
    interface ProcessEnv {
      REACT_APP_SERVER_URL: string;
      REACT_APP_KEYCLOAK_URL: string;
    }
  }
  interface Window {
    env: NodeJS.ProcessEnv;
  }
}

const initOptions = {
  onLoad: "check-sso",
  silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
  checkLoginIframe: false,
  enableLogging: true,
};

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Sometimes the state is modified when passed as prop, so this check is disabled until the problem is solved
      immutableCheck: false,
    }),
});
const rootElement = document.getElementById("root")!;
const root = ReactDOM.createRoot(rootElement);
root.render(
  <ReactKeycloakProvider authClient={keycloak} initOptions={initOptions}>
    <Provider store={store}>
      <ThemeProvider theme={ImsMaterialTheme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </ReactKeycloakProvider>
);
