import { Typography, useTheme } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { DEFAULT_LIMIT } from "../../api/SnowflakeApi";
import { IStore } from "../../store/IStore";
import { CustomerRegistrationDto } from "../../store/models/dashboard/CustomerRegistrationDto";
import { KNISTR_GREEN } from "../atoms/ImsMaterialTheme";
import { chartColors, formatAxisDate } from "./MainDashboardPage";
import { useTranslation } from "react-i18next";

const CustomerRegistrationChart = (props: ThunkProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { customerRegistrations } = props;
  if (!customerRegistrations) return null;
  return (
    <div style={{ width: "100%", height: 300 }}>
      <Typography
        variant="h4"
        style={{
          paddingLeft: theme.spacing(8),
          paddingBottom: theme.spacing(2),
        }}
      >
        {t("dashboard.newMemberOfThePastXDays", { day: ((customerRegistrations.offset || 0) + 1) * DEFAULT_LIMIT })}
      </Typography>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={customerRegistrations.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {chartColors()}
          <CartesianGrid strokeDasharray={3} />
          <XAxis
            height={32}
            dataKey={(item: CustomerRegistrationDto) => formatAxisDate(item.REGISTRATION_TS)}
            tick={<MultilineTick />}
          />
          <YAxis />
          <Tooltip />

          <Bar
            dataKey={(value: CustomerRegistrationDto) => Number(value.CUSTOMER_CNT)}
            name={t("dashboard.newRegistration")}
            stroke={KNISTR_GREEN}
            fill={KNISTR_GREEN + "A0"}
            barSize={Number(theme.spacing(4))}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const MultilineTick = (props: any) => {
  if (!props.payload.value) return null;
  return (
    <g transform={`translate(${props.x},${props.y})`}>
      <text x={0} y={0} dy={12} fill="#666">
        {(props.payload.value as string).split("\n").map((item, index) => (
          <tspan key={index} textAnchor="middle" x="0" dy={index ? index * 16 : undefined}>
            {item}
          </tspan>
        ))}
      </text>
    </g>
  );
};

const mapStateToProps = (state: IStore) => ({
  customerRegistrations: state.dashboard.customerRegistrations,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(CustomerRegistrationChart);
