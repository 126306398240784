import { Grid, Paper, Tooltip, useTheme } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkGetFamilyByCustomerNumber } from "../../store/actions/FamilyActions";
import { IStore } from "../../store/IStore";
import ImsPaperHead from "../atoms/ImsPaperHead";
import { useTranslation } from "react-i18next";
import { Api } from "../../api/Api";
import { FC, ReactNode, useState } from "react";
import { Help } from "@mui/icons-material";
import { formatGermanDate, formatNumberOrDefault } from "../atoms/Utils";
import i18n from "../../i18n";

const CollectionInterval = (props: ThunkProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [point, setPoint] = useState<string>();
  const [intervalFrom, setIntervalFrom] = useState<string>();
  const [intervalTo, setIntervalTo] = useState<string>();
  const [bonusCurrency, setBonusCurrency] = useState<string>();

  if (props.customerNumber) {
    Api.getCustomerCollectionInterval(props.customerNumber)
      .then((data) => {
        setPoint(data.points);
        setIntervalFrom(data.collectionIntervalFrom);
        setIntervalTo(data.collectionIntervalTill);
        setBonusCurrency(data.bonusCurrency);
      })
      .catch((e) => {});
  } else {
    return <Empty />;
  }

  return (
    <Grid height={"100%"}>
      <Paper
        style={{
          padding: theme.spacing(3),
          height: "100%",
        }}
      >
        <ImsPaperHead text={t("customerCareDetailsPage.headlineCollectionInterval")} />

        <Grid
          container
          style={{
            padding: theme.spacing(3, 0),
          }}
        >
          {intervalFrom && intervalTo ? (
            <>
              <Grid item xs={12}>
                <b>{t("customerCareDetailsPage.labelCurrentCollectionInterval")}</b>
              </Grid>
              <Grid item xs={12}>
                <Date>
                  {formatGermanDate(intervalFrom)} - {formatGermanDate(intervalTo)}
                </Date>
              </Grid>
            </>
          ) : (
            <>{t("customerCareDetailsPage.labelNoCurrentCollectionInterval")}</>
          )}
        </Grid>

        <Grid
          container
          justifyContent="left"
          style={{
            padding: theme.spacing(3, 0),
          }}
        >
          {point && (
            <>
              <Grid item xs={12}>
                <b>{mapLabelCollectedBonusCurrency(bonusCurrency)}</b>
                <span style={{ marginLeft: "10px" }}></span>
                <Tooltip title={t("customerCareDetailsPage.tooltipCollectedPoint")} placement="right">
                  <Help color="primary" />
                </Tooltip>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  marginBottom: theme.spacing(1.5),
                  fontSize: 42,
                }}
              >
                <Number>{formatNumberOrDefault(point)}</Number>
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

const mapLabelCollectedBonusCurrency = (currency?: string) => {
  switch (currency) {
    case "BONUS_POINT":
      return i18n.t("customerCareDetailsPage.labelCollectedBonusPoint");
    case "REWARD_POINT":
      return i18n.t("customerCareDetailsPage.labelCollectedStatusPoint");
    case "PAYMENT_AMOUNT":
      return i18n.t("customerCareDetailsPage.labelCollectedTurnover");
    default:
      return "";
  }
};

const Empty = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Grid height={"100%"}>
      <Paper
        style={{
          padding: theme.spacing(3),
          height: "100%",
        }}
      >
        <ImsPaperHead text={t("customerCareDetailsPage.headlineCollectionInterval")} />
        <Grid
          container
          style={{
            padding: theme.spacing(3, 0),
          }}
        >
          <Grid item xs={12}>
            <b>{t("customerCareDetailsPage.labelNoCurrentCollectionInterval")}</b>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const Date: FC<{ children?: ReactNode }> = ({ children }) => {
  return <div style={{ fontSize: 32 }}>{children}</div>;
};

const Number: FC<{ children?: ReactNode }> = ({ children }) => {
  return <div style={{ fontSize: 42 }}>{children}</div>;
};

const mapStateToProps = (state: IStore) => ({
  customerNumber: state.customerCare.customer?.customerNumber,
  family: state.customerCare.family,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkGetFamilyByCustomerNumber }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(CollectionInterval);
