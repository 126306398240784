import { Api } from "../../api/Api";
import { BooleanThunk } from "../IStore";
import { thunkCreateErrorNotification } from "./NotificationActions";

export const FIND_COUPONS_BY_CUSTOMER_NUMBER = "FIND_COUPONS_BY_CUSTOMER_NUMBER";

export const thunkFindCoupons =
  (customerNumber: string): BooleanThunk =>
  async (dispatch) => {
    try {
      const coupons = await Api.findCoupons(customerNumber);
      dispatch({
        type: FIND_COUPONS_BY_CUSTOMER_NUMBER,
        payload: coupons,
      });
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to load coupons", e));
      return false;
    }
  };
