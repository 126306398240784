import { Container, Typography, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ErrorPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const navigateToHome = () => {
    navigate("/");
  };

  return (
    <Container>
      <Box textAlign="center" mt={5}>
        <Typography variant="h1" component="h1" gutterBottom>
          {t("errorPage.title")}
        </Typography>
        <Typography variant="body1">{t("errorPage.message")}</Typography>
        <Box mt={3}>
          <Button variant="contained" color="primary" onClick={navigateToHome}>
            {t("errorPage.button")}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ErrorPage;
