import { FormOption } from "../../../components/atoms/FormOption";
import { COLOR_GRAY_4, KNISTR_GREEN, KNISTR_RED } from "../../../components/atoms/ImsMaterialTheme";
import { TFunction } from "i18next";

export interface CustomerStatusDto {
  status: CustomerStatus;
  note: string;
}

export enum CustomerStatus {
  NONE = "NONE",
  REGISTERED = "REGISTERED",
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED",
  ARCHIVED = "ARCHIVED",
  MIGRATED = "MIGRATED",
  CONSENT_FAILED = "CONSENT_FAILED",
  CANCELLED = "CANCELLED",
  REVOKE_CANCELLATION = "REVOKE_CANCELLATION",
}

export function getCustomerStatusText(status: CustomerStatus, t: TFunction): string {
  switch (status) {
    case CustomerStatus.NONE:
      return t("customerStatus.none");
    case CustomerStatus.REGISTERED:
      return t("customerStatus.registered");
    case CustomerStatus.ACTIVE:
      return t("customerStatus.active");
    case CustomerStatus.DISABLED:
      return t("customerStatus.disabled");
    case CustomerStatus.ARCHIVED:
      return t("customerStatus.archived");
    case CustomerStatus.MIGRATED:
      return t("customerStatus.migrated");
    case CustomerStatus.CONSENT_FAILED:
      return t("customerStatus.consentFailed");
    case CustomerStatus.CANCELLED:
      return t("customerStatus.cancelled");
    case CustomerStatus.REVOKE_CANCELLATION:
      return t("customerStatus.revokeCancellation");
    default:
      return t("customerStatus.unknown");
  }
}

export function getCustomerStatusColor(status: CustomerStatus): string {
  switch (status) {
    case CustomerStatus.ACTIVE:
      return KNISTR_GREEN;
    case CustomerStatus.DISABLED:
      return KNISTR_RED;
    default:
      return COLOR_GRAY_4;
  }
}

export const customerStatusOptions = (t: TFunction): FormOption[] => [
  {
    value: CustomerStatus.ACTIVE,
    label: t("customerStatus.active"),
  },
  {
    value: CustomerStatus.DISABLED,
    label: t("customerStatus.disabled"),
  },
  {
    value: CustomerStatus.NONE,
    label: t("customerStatus.none"),
    disabled: true,
  },
  {
    value: CustomerStatus.REGISTERED,
    label: t("customerStatus.registered"),
    disabled: true,
  },
  {
    value: CustomerStatus.ARCHIVED,
    label: t("customerStatus.archived"),
    disabled: true,
  },
  {
    value: CustomerStatus.MIGRATED,
    label: t("customerStatus.migrated"),
    disabled: true,
  },
  {
    value: CustomerStatus.CONSENT_FAILED,
    label: t("customerStatus.consentFailed"),
    disabled: true,
  },
  {
    value: CustomerStatus.CANCELLED,
    label: t("customerStatus.cancelled"),
    disabled: true,
  },
];
