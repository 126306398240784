import { Api } from "../../api/Api";
import { BooleanThunk } from "../IStore";
import { MembershipCancellationDto } from "../models/customer/MembershipCancellationDto";
import { thunkCreateErrorNotification, thunkCreateSuccessNotification } from "./NotificationActions";

export const thunkCancelCustomerMembership =
  (membershipCancellation: MembershipCancellationDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.cancelCustomerMembership(membershipCancellation);
      dispatch(thunkCreateSuccessNotification("Kündigung erfolgreich übermittelt"));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to cancel customer membership", e));
      return false;
    }
  };

export const thunkRevokeCancelCustomerMembership =
  (customerNumber?: string): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.revokeCancelCustomerMembership(customerNumber);
      dispatch(thunkCreateSuccessNotification("Kündigung erfolgreich zurückgenommen"));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to revoke the cancellation", e));
      return false;
    }
  };
