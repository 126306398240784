import { Api } from "../../api/Api";
import { BooleanThunk } from "../IStore";
import { InteractionSearchDto } from "../models/interactionSearch/InteractionSearchDto";
import { thunkCreateErrorNotification } from "./NotificationActions";

export const INTERACTION_SEARCH = "INTERACTION_SEARCH";

export const thunkInteractionSearch =
  (interactionSearch: InteractionSearchDto): BooleanThunk =>
  async (dispatch) => {
    try {
      const interactions = await Api.interactionSearch(interactionSearch);
      dispatch({
        type: INTERACTION_SEARCH,
        payload: interactions,
      });
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Error in interaction search", e));
      return false;
    }
  };
