import {Api} from "../../api/Api";
import {BooleanThunk} from "../IStore";
import {thunkCreateErrorNotification, thunkCreateSuccessNotification} from "./NotificationActions";
import {CarouselInboundDto} from "../models/appcontent/CarouselInboundDto";
import {CarouselDto} from "../models/appcontent/CarouselDto";

export const CREATE_CAROUSEL = "CREATE_CAROUSEL";
export const GET_ALL_CAROUSELS = "GET_ALL_CAROUSELS";
export const DELETE_CAROUSEL = "DELETE_CAROUSEL";
export const UPDATE_CAROUSEL = "UPDATE_CAROUSEL";
export const GET_CAROUSEL_BY_NUMBER = "GET_CAROUSEL_BY_NUMBER";
export const CHANGE_CAROUSELS = "CHANGE_CAROUSELS";
export const thunkCreateCarousel =
  (carouselToCreate: CarouselInboundDto, imageFile: File): BooleanThunk =>
  async (dispatch) => {
    try {
      const carousel = await Api.createCarousel(carouselToCreate, imageFile);
      dispatch({
        type: CREATE_CAROUSEL,
        payload: carousel,
      });
      dispatch(thunkCreateSuccessNotification("Carousel erfolgreich erstellt"));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to create the carousel", e));
      return false;
    }
  };
export const thunkGetAllCarousels = (): BooleanThunk => async (dispatch) => {
  try {
    const carousels = await Api.getAllCarousels();
    dispatch({
      type: GET_ALL_CAROUSELS,
      payload: carousels,
    });
    return true;
  } catch (e) {
    dispatch(thunkCreateErrorNotification("Failed to load carousel", e));
    return false;
  }
};

export const thunkChangeCarousel =
  (carouselToChange: CarouselDto[]): BooleanThunk =>
  async (dispatch) => {
    try {
      const carousels = await Api.changeCarousel(carouselToChange);
      dispatch({
        type: CHANGE_CAROUSELS,
        payload: carousels,
      });
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to change the carousel", e));
      return false;
  }
};

export const thunkDeleteCarousel =
  (carouselToDelete: CarouselDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.deleteCarousel(carouselToDelete);
      dispatch({
        type: DELETE_CAROUSEL,
        payload: carouselToDelete,
      });
      dispatch(thunkCreateSuccessNotification("Carousel erfolgreich gelöscht"));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to delete the carousel", e));
      return false;
    }
  };

export const thunkUpdateCarousel =
  (carouselToUpdate: CarouselInboundDto, imageFile: File, carouselNumber: string): BooleanThunk =>
  async (dispatch) => {
    try {
      const carousel = await Api.updateCarousel(carouselToUpdate, imageFile, carouselNumber);
      dispatch({
        type: UPDATE_CAROUSEL,
        payload: carousel,
      });
      dispatch(thunkCreateSuccessNotification("Carousel erfolgreich bearbeitet"));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to edit the carousel", e));
      return false;
    }
  };
export const thunkGetCarouselByNumber =
  (carouselNumber: string): BooleanThunk =>
  async (dispatch) => {
    try {
      const carousel = await Api.getCarouselByNumber(carouselNumber);
      dispatch({
        type: GET_CAROUSEL_BY_NUMBER,
        payload: carousel,
      });
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to load carousel", e));
      return false;
    }
  };
