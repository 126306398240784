import { Api } from "../../api/Api";
import { BooleanThunk, BooleanThunkAction } from "../IStore";
import { ConfigGroupDto, ConfigValueDto } from "../models/config/ConfigDto";
import { ConfigGroupKey } from "../models/config/ConfigGroupKey";
import { thunkCreateErrorNotification, thunkCreateSuccessNotification } from "./NotificationActions";

export const GET_ALL_CONFIG_GROUPS = "GET_ALL_CONFIG_GROUPS";

export const thunkGetAllConfigGroups = (): BooleanThunk => async (dispatch) => {
  try {
    const payload: ConfigGroupDto[] = [];

    for (let configGroupKey of [
      ConfigGroupKey.RECEIPT_DETAIL,
      ConfigGroupKey.CUSTOMER_PROPERTY,
      ConfigGroupKey.CUSTOM_FIELD,
    ]) {
      try {
        const configGroup = await Api.getConfigGroupBy(configGroupKey);
        payload.push(configGroup);
      } catch (e) {
        if (e !== "No Config group found for Config group Key " + configGroupKey) {
          dispatch(thunkCreateErrorNotification("Failed to load configuration", e));
        }
      }
    }
    dispatch({
      type: GET_ALL_CONFIG_GROUPS,
      payload,
    });
    return true;
  } catch (e) {
    dispatch(thunkCreateErrorNotification("Failed to load configuration group", e));
    return false;
  }
};

export const thunkCreateConfigValue =
  (configValueToCreate: ConfigValueDto, configGroupKey: ConfigGroupKey): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.createConfigValue(configGroupKey, configValueToCreate);
      dispatch(thunkGetAllConfigGroups() as BooleanThunkAction);
      dispatch(thunkCreateSuccessNotification("Der Konfigurationswert wurde angelegt"));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to create the configuration value", e));
      return false;
    }
  };

export const thunkUpdateConfigValue =
  (configValueToUpdate: ConfigValueDto, configGroupKey: ConfigGroupKey): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.updateConfigValue(configGroupKey, configValueToUpdate);
      dispatch(thunkGetAllConfigGroups() as BooleanThunkAction);
      dispatch(thunkCreateSuccessNotification("Der Konfigurationswert wurde aktualisiert"));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to update the configuration value", e));
      return false;
    }
  };

export const thunkDeleteConfigValue =
  (configValueToDelete: ConfigValueDto, configGroupKey: ConfigGroupKey): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.deleteConfigValue(configGroupKey, configValueToDelete);
      dispatch(thunkGetAllConfigGroups() as BooleanThunkAction);
      dispatch(thunkCreateSuccessNotification("Der Konfigurationswert wurde gelöscht"));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to delete the configuration value", e));
      return false;
    }
  };
