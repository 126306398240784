import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../store/IStore";
import { BonusRuleDto } from "../../store/models/bonusRule/BonusRuleDto";
import { BonusRuleStatus } from "../../store/models/bonusRule/BonusRuleStatus";
import { BonusRuleType } from "../../store/models/bonusRule/BonusRuleType";
import { BonusRuleValueType } from "../../store/models/bonusRule/BonusRuleValueType";
import { OperationalUnitDto } from "../../store/models/operationalUnit/OperationalUnitDto";
import { DateTag } from "../atoms/DateTag";
import { ImsHeadCell, ImsTable, ImsTableRow } from "../atoms/ImsTable";
import { getCurrentDateAsString } from "../atoms/Utils";
import { getInteractionTypeOptions } from "./BonusRuleForm";
import BonusRuleMenu from "./BonusRuleMenu";
import { BonusSearchCondition } from "./BonusRulePage";

export function getUnitNames(unitNumbers: string[], operationalUnits: OperationalUnitDto[]): string {
  const unitNames = operationalUnits
    .filter((unit) => unitNumbers.includes(unit.unitNumber))
    .slice(0, 5)
    .map((unit) => unit.unitName);

  if (unitNames.length > 0) {
    return unitNames.join(", ") + (unitNumbers.length > 5 ? "..." : "");
  }

  return "";
}

function isActualRunning(bonusRule: BonusRuleDto): boolean {
  if (bonusRule.validTill) {
    // if and end date is exist then it must be in the future
    return bonusRule.validTill >= getCurrentDateAsString();
  }
  return true;
}

function isActive(bonusRule: BonusRuleDto): boolean {
  return bonusRule.status !== BonusRuleStatus.DISABLED;
}

function isUnitNumberMatched(bonusRule: BonusRuleDto, conditionUnitNumbers: string[]): boolean {
  if (conditionUnitNumbers.length > 0) {
    return conditionUnitNumbers.some((unitNumber) => bonusRule.unitNumbers.includes(unitNumber));
  }

  //No given unit numbers to filter  => skip checking
  return true;
}

interface BonusRuleTableProps {
  searchCondition: BonusSearchCondition;
}

const BonusTable = (props: BonusRuleTableProps & ThunkProps) => {
  const { t } = useTranslation();

  const interactionTypeOptions = getInteractionTypeOptions(t);

  function getCustomerInteractionDescription(row: BonusRuleDto): string {
    if (row.type === BonusRuleType.TRANSACTION) {
      return t("bonusRuleTable.purchase");
    }
    const interactionTypeOption = interactionTypeOptions.find(
      (interactionTypeOption) => interactionTypeOption.value === row.customerInteractionType
    );
    return interactionTypeOption?.label || t("bonusRuleTable.ownActivity");
  }

  function getValueTypeDescription(valueType: BonusRuleValueType): string {
    const valueDescriptions = {
      [BonusRuleValueType.FIX]: "",
      [BonusRuleValueType.FACTOR]: t("bonusRuleTable.factor"),
      [BonusRuleValueType.PER_CENT]: t("bonusRuleTable.perCent"),
    };
    return valueDescriptions[valueType] || "";
  }

  const headCells: ImsHeadCell<BonusRuleDto>[] = [
    { val: (val) => val.ruleName, node: t("bonusRuleTable.name") },
    { val: (val) => val.validFrom, node: t("bonusRuleTable.execution") },
    { val: (val) => val.unitNumbers, node: [t("bonusRuleTable.operationalUnit")] },
    { val: (val) => val.value, node: t("bonusRuleTable.bonusPoints") },
    { val: (val) => val.type, node: t("bonusRuleTable.activity") },
    { val: (val) => val.status, node: t("bonusRuleTable.status") },
  ];

  const rows: ImsTableRow<BonusRuleDto>[] = props.bonusRules
    .filter((row) =>
      props.searchCondition.name ? row.ruleName.toLowerCase().includes(props.searchCondition.name.toLowerCase()) : true
    )
    .filter((row) => (props.searchCondition.isRunning ? isActualRunning(row) : true))
    .filter((row) => (props.searchCondition.showInactive ? true : isActive(row)))
    .filter((row) =>
      props.searchCondition.unitNumbers ? isUnitNumberMatched(row, props.searchCondition.unitNumbers) : true
    )
    .map((row) => ({
      value: row,
      nodes: [
        <b>{row.ruleName}</b>,
        <>
          {!row.validTill ? t("bonusRuleTable.from") : ""} <DateTag date={row.validFrom} />
          {row.validTill ? t("bonusRuleTable.to") : ""} <DateTag date={row.validTill} />
        </>,
        getUnitNames(row.unitNumbers, props.operationalUnits),
        Number(row.value) + getValueTypeDescription(row.valueType),
        getCustomerInteractionDescription(row),
        row.status === BonusRuleStatus.ENABLED ? t("bonusRuleTable.active") : t("bonusRuleTable.inactive"),
        <BonusRuleMenu bonusRule={row} />,
      ],
      disabled: row.status === BonusRuleStatus.DISABLED,
    }));

  return (
    <Grid>
      <ImsTable hasMenu={true} headCells={headCells} rows={rows} />
    </Grid>
  );
};

const mapStateToProps = (state: IStore) => ({
  bonusRules: state.bonusRules.bonusRules,
  operationalUnits: state.operationalUnits.operationalUnits,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(BonusTable);
