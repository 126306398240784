import { Brightness1 } from "@mui/icons-material";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../../store/IStore";
import { IdentificationTypeDto } from "../../../store/models/identification/IdentificationTypeDto";
import { KNISTR_GREEN, KNISTR_RED } from "../../atoms/ImsMaterialTheme";
import { ImsHeadCell, ImsTable, ImsTableRow } from "../../atoms/ImsTable";
import IdentificationTypeTableMenu from "./IdentificationTypeTableMenu";
import { useTranslation } from "react-i18next";

const IdentificationTypeTable = (props: ThunkProps) => {
  const { identificationTypes } = props;
  const { t } = useTranslation();
  const headCells: ImsHeadCell<IdentificationTypeDto>[] = [
    { val: (val) => val.identificationTypeNumber, node: t("setup.headlineId") },
    { val: (val) => val.identificationTypeName, node: t("setup.headlineDesignation") },
    {
      val: (val) => val.defaultIdentification,
      node: t("setup.headlineStandardTouchpoint"),
    },
    {
      val: (val) => Boolean(val.templateName),
      node: t("setup.headlineIdGeneration"),
    },
    {
      val: (val) => val.templateName,
      node: t("setup.headlinePassIdPattern"),
    },
  ];
  const showBooleanLight = (isOn: boolean) => {
    return (
      <Brightness1
        style={{
          fill: isOn ? KNISTR_GREEN : KNISTR_RED,
          paddingTop: 4,
        }}
      />
    );
  };

  const rows: ImsTableRow<IdentificationTypeDto>[] = identificationTypes?.map((row: IdentificationTypeDto) => ({
    value: row,
    nodes: [
      row.externalIdentificationTypeNumber,
      row.identificationTypeName,
      showBooleanLight(row.defaultIdentification),
      showBooleanLight(Boolean(row.templateName)),
      row.templateName,

      <IdentificationTypeTableMenu identificationType={row} />,
    ],
  }));
  return rows.length ? (
    <ImsTable hasMenu={true} headCells={headCells} rows={rows} sortColumnIndex={1} sortDirection="desc" />
  ) : (
    <>{t("setup.noData")}</>
  );
};

const mapStateToProps = (state: IStore) => ({
  identificationTypes: state.identifications?.identificationTypes,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(IdentificationTypeTable);
