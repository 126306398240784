import { ArrowBackIos, ArrowForwardIos, DataUsage, DirectionsRun, NotInterested } from "@mui/icons-material";
import { Grid, Paper, Typography, useTheme } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkGetCustomerStatuses } from "../../store/actions/DashboardActions";
import { IStore } from "../../store/IStore";
import { COLOR_BLACK, COLOR_GRAY_1, COLOR_GRAY_2, KNISTR_BLUE, KNISTR_PLUM } from "../atoms/ImsMaterialTheme";
import CustomerStatusChart from "./CustomerStatusChart";
import CustomerStatusCount from "./CustomerStatusCount";
import { useTranslation } from "react-i18next";

const CustomerStatusPanel = (props: ThunkProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { customerStatuses } = props;
  if (!customerStatuses) {
    props.thunkGetCustomerStatuses();
    return null;
  }
  return (
    <>
      <Paper
        style={{
          padding: theme.spacing(3),
          paddingBottom: theme.spacing(6),
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(6),
        }}
      >
        <Grid item xs={12} style={{ marginBottom: theme.spacing(3) }}>
          <Typography variant="h3">{t("dashboard.customerStatusChanges")}</Typography>
        </Grid>
        <Grid item md={12} style={{ display: "flex", alignItems: "center" }}>
          <Grid item>
            <ArrowBackIos
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.thunkGetCustomerStatuses((customerStatuses.offset || 0) + 1);
              }}
            />
          </Grid>
          <Grid item md>
            <CustomerStatusChart />
          </Grid>
          <Grid item>
            <ArrowForwardIos
              onClick={() => {
                if (customerStatuses.offset) props.thunkGetCustomerStatuses(customerStatuses.offset - 1);
              }}
              style={{
                color: customerStatuses.offset ? COLOR_BLACK : COLOR_GRAY_1,
                cursor: customerStatuses.offset ? "pointer" : undefined,
              }}
            />
          </Grid>
          <Grid
            item
            md={3}
            style={{
              marginLeft: theme.spacing(1),
            }}
          >
            <Grid item md>
              <CustomerStatusCount
                status={t('dashboard.status.active')}
                icon={<DirectionsRun fontSize="large" style={{ marginLeft: theme.spacing(1) }} />}
                field="CUS_STATUS_ACTIVE_CNT"
                color={KNISTR_BLUE}
              />
              <CustomerStatusCount
                status={t('dashboard.status.blocked')}
                icon={<NotInterested fontSize="large" style={{ marginLeft: theme.spacing(1) }} />}
                field="CUS_STATUS_DISABLED_CNT"
                color={KNISTR_PLUM}
              />
              <CustomerStatusCount
                status={t('dashboard.status.other')}
                icon={<DataUsage fontSize="large" style={{ marginLeft: theme.spacing(1) }} />}
                field="CUS_STATUS_OTHER_CNT"
                color={COLOR_GRAY_2}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

const mapStateToProps = (state: IStore) => ({
  customerStatuses: state.dashboard.customerStatuses,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkGetCustomerStatuses }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(CustomerStatusPanel);
